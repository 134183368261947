import { IconButton } from "@mui/material";
import CancelOutlinedIcon from "@mui/icons-material/CancelOutlined";
import ErrorOutlineIcon from "@mui/icons-material/ErrorOutline";
import InputAdornment from "@mui/material/InputAdornment";
import { ThemeProvider } from "@mui/material/styles";
import TextField from "@mui/material/TextField";
import { useEffect, useState } from "react";
import InputMask from "react-input-mask";
import "../../assets/stylesheets/_variables.scss";
import { InputTextFieldTheme } from "./common/Themes";
import { InputBoxStyleGuide } from "./StyleGuideCSS";
import FormHelperText from "@mui/material/FormHelperText";
import FormControl from "@mui/material/FormControl";
import {PHONE_NUMBER_MASK} from "../../constants";

export default function InputTextField(props) {
  const [value, setValue] = useState(props.value || "");
  const [error, setError] = useState(props.error || false);

  useEffect(() => {
    setValue(props.value);
  }, [props.value]);

  useEffect(() => {
    setError(props.error);
  }, [props.error]);

  useEffect(() => {
    if (!props.disabled && props.onChange) props.onChange(props.name, value);
  }, [value]);

  const handleChange = (e) => {
    if (error) {
      setError(false);
    }
    setValue(e.target.value);
  };

  const errorIcon = {
    endAdornment: (
      <InputAdornment position="end">
        <ErrorOutlineIcon
          sx={{ color: `${InputBoxStyleGuide.colorAlertNegative}` }}
        />
      </InputAdornment>
    ),
  };

  const clearButton = {
    endAdornment: (
      <IconButton
        aria-label="toggle password visibility"
        onClick={() => setValue("")}
      >
        <CancelOutlinedIcon
          fontSize="small"
          sx={{ color: "#575558", height: "20px", width: "20px" }}
        />
      </IconButton>
    ),
  };

  return (
    <ThemeProvider theme={InputTextFieldTheme}>
      {props.type === "phone" ? (
        <InputMask
          mask={PHONE_NUMBER_MASK}
          maskChar=""
          value={value}
          onChange={handleChange}
        >
          {() => (
            <TextField
              sx={{
                ...props.sx,
                fontSize: "16px",
              }}
              error={error}
              id={props.id || "outlined-basic"}
              label={props.label || "Enter Text"}
              variant={props.variant || "outlined"}
              name={props.name || "text"}
              color="primary"
              value={value ? value : ""}
              fullWidth
              helperText={error && props.errorText ? props.errorText : ""}
              InputProps={
                !props.disabled
                  ? error
                    ? errorIcon
                    : props.value
                    ? clearButton
                    : {}
                  : null
              }
              inputProps={{
                maxLength: props.maxLength ? props.maxLength : 150,
              }}
              required={props.required}
              onChange={handleChange}
              size="normal"
            />
          )}
        </InputMask>
      ) : (
        <TextField
          sx={{
            ...props.sx,
            fontSize: "16px",
          }}
          error={error}
          id={props.id || "outlined-basic"}
          label={props.label || "Enter Text"}
          variant={props.variant || "outlined"}
          name={props.name || "text"}
          color="primary"
          value={value ? value : ""}
          fullWidth
          helperText={error && props.errorText ? props.errorText : ""}
          InputProps={
            !props.disabled
              ? error
                ? errorIcon
                : props.value
                ? clearButton
                : {}
              : null
          }
          inputProps={{ maxLength: props.maxLength ? props.maxLength : 150 }}
          required={props.required}
          onChange={handleChange}
          size="normal"
          disabled={props.disabled}
        />
      )}
    </ThemeProvider>
  );
}
