import React from 'react';
import PropTypes from 'prop-types';
import { Modal } from 'react-bootstrap';
import SpinnerImage from 'assets/images/spinner.gif';
import Logo from "../../assets/images/medtronic.png";

import './lightbox_modal.scss';


class LightboxModal extends React.PureComponent { // eslint-disable-line react/prefer-stateless-function
  render() {
    return (
      <Modal
        show={this.props.show}
        container={document.body}
        keyboard={false}
        aria-labelledby="lightbox-modal"
        className="lightbox-modal"
      >
        <Modal.Body>
          {/* <span className="spinner-image">Please Wait</span> */}
          {/* <img src={Logo} alt="Loading..." className="spinner-image" /> */}
          <div class="loader"></div>
        </Modal.Body>
      </Modal>
    );
  }
}

LightboxModal.propTypes = {
  show: PropTypes.bool,
};

export default LightboxModal;
