import Select from './SingleSelect';
import SingleSelectNoBorder from './SingleSelectNoBorder';
import MultiSelectComponent from './MultiSelect';
import CustomizedMultiSelect from './CustomizedMultiSelect';
import MultiSelectPill from './MultiSelectPill';
import './style.scss';

export const SingleSelectWithoutBorder = SingleSelectNoBorder;

export const MultiSelect = MultiSelectComponent;

export const CustomMultiSelect = CustomizedMultiSelect;

export const MultiSelectForPill = MultiSelectPill;

export default Select;
