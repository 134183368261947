/*
 *
 * UserCookieConsent reducer
 *
 */
import {
  GET_COOKIE_CONSENT_REQUEST_SENT,
  GET_COOKIE_CONSENT_REQUEST_SUCCEED,
  GET_COOKIE_CONSENT_REQUEST_FAILED,
} from './constants';

const initState = {
  cookieConsentText: ""
};

export const getCookieConsentReducer = (state = initState, action) => {
  switch (action.type) {
    case GET_COOKIE_CONSENT_REQUEST_SUCCEED:
      return {
        ...state,
        cookieConsentText: action.data.cookies_consent,
      }
    case GET_COOKIE_CONSENT_REQUEST_SENT:
    case GET_COOKIE_CONSENT_REQUEST_FAILED:

    default:
      return state;
  }
};
