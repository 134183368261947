import { showToaster } from 'common/toasterActions';
import {
  GET_SURVEY_FEEDBACK_REQUEST_SENT,
  GET_SURVEY_FEEDBACK_REQUEST_SUCCEED,
  GET_SURVEY_FEEDBACK_REQUEST_FAILED,

  SUBMIT_SURVEY_FEEDBACK_REQUEST_SENT,
  SUBMIT_SURVEY_FEEDBACK_REQUEST_SUCCEED,
  SUBMIT_SURVEY_FEEDBACK_REQUEST_FAILED,
  CLEAR_SURVEY_FEEDBACK_QUESTIONS,
} from './constants';

import { getSurveyFeedback, submitSuveryFeedback } from '../../../services/patient/patientDashboard';


export const getSurveyFeedbackRequestSent = () => ({
  type: GET_SURVEY_FEEDBACK_REQUEST_SENT,
});

export const getSurveyFeedbackRequestSucceeded = (data) => ({
  type: GET_SURVEY_FEEDBACK_REQUEST_SUCCEED,
  data,
});

export const getSurveyFeedbackRequestFailed = () => ({
  type: GET_SURVEY_FEEDBACK_REQUEST_FAILED,
});

export const submitSuveryFeedbackRequestSent = () => ({
  type: SUBMIT_SURVEY_FEEDBACK_REQUEST_SENT,
});

export const submitSuveryFeedbackRequestSucceeded = () => ({
  type: SUBMIT_SURVEY_FEEDBACK_REQUEST_SUCCEED,
});

export const submitSuveryFeedbackRequestFailed = () => ({
  type: SUBMIT_SURVEY_FEEDBACK_REQUEST_FAILED,
});


export const clearSurveyQuestionsRequest = () => ({
  type: CLEAR_SURVEY_FEEDBACK_QUESTIONS,
});

// //////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
// Redux Thunk actions:
// //////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
export const getSurveyFeedbackRequest = (eventId) => (dispatch) => {
  dispatch(getSurveyFeedbackRequestSent());
  getSurveyFeedback(eventId)
    .then((response) => {
      dispatch(getSurveyFeedbackRequestSucceeded(response.data));
    })
    .catch((error) => {
      dispatch(getSurveyFeedbackRequestFailed());
      dispatch(showToaster({ type: 'error', message: error.response.data.error }));
    });
};

export const submitSuveryFeedbackRequest = (eventId, params) => (dispatch) => {
  dispatch(submitSuveryFeedbackRequestSent());
  submitSuveryFeedback(params)
    .then((response) => {
      dispatch(submitSuveryFeedbackRequestSucceeded(response.data));
      dispatch(showToaster({ message: 'Your Response is recorded. Thank you' }));
      dispatch(getSurveyFeedbackRequest(eventId));
    })
    .catch((error) => {
      dispatch(submitSuveryFeedbackRequestFailed());
      dispatch(showToaster({ type: 'error', message: error.response.data.error }));
    });
};
