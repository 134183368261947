import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';

class HelpBlock extends PureComponent {
  render() {
    const customClass = this.props.className ? this.props.className : '';
    return (
      <React.Fragment>
        {this.props.value !== undefined &&
          <span className={`help-block ${customClass}`}>{this.props.value}</span>
        }
      </React.Fragment>
    );
  }
}

HelpBlock.propTypes = {
  value: PropTypes.any,
  className: PropTypes.any,
};

export default HelpBlock;
