/*
 *
 * PatientRegister Page actions
 *
 */
import {
  createPatient as CreatePatientApiCall,
  generateOtp as GenerateOtpApiCall,
  getFacilityApiCall,
  getFlowId as FlowIdApiCall,
  getLocationProviders as LocationProvidersApiCall,
  getModifierApiCall,
  saveProcedure as saveProcedureApiCall,
  verifyOtp as VerifyOtpApiCall,
} from "services/patientDetails";

import {
  CLEAR_SIGNUP_DATA,
  CREATE_PATIENT_REQUEST_FAILED,
  CREATE_PATIENT_REQUEST_SENT,
  CREATE_PATIENT_REQUEST_SUCCEED,
  GENERATE_OTP_REQUEST_FAILED,
  GENERATE_OTP_REQUEST_SENT,
  GENERATE_OTP_REQUEST_SUCCEED,
  GET_FACILITY_REQUEST_FAILED,
  GET_FACILITY_REQUEST_SENT,
  GET_FACILITY_REQUEST_SUCCEED,
  GET_FLOW_ID_REQUEST_FAILED,
  GET_FLOW_ID_REQUEST_SENT,
  GET_FLOW_ID_REQUEST_SUCCEED,
  GET_LOCATION_PROVIDERS_REQUEST_FAILED,
  GET_LOCATION_PROVIDERS_REQUEST_SENT,
  GET_LOCATION_PROVIDERS_REQUEST_SUCCEED,
  GET_MODIFIER_REQUEST_FAILED,
  GET_MODIFIER_REQUEST_SENT,
  GET_MODIFIER_REQUEST_SUCCEED,
  PAGE_CHANGE,
  SAVE_PROCEDURE_REQUEST_FAILED,
  SAVE_PROCEDURE_REQUEST_SENT,
  SAVE_PROCEDURE_REQUEST_SUCCEEDED,
  SET_EMAIL_OTP_IN_STATE,
  SET_EXISTING_USER_FLAG,
  SET_PHONE_OTP_IN_STATE,
  SET_PROCEDURE_CONFIRMATION,
  SET_QR_DETAILS,
  SET_SIGNUP_DATA,
  SET_SIGNUP_DATA_FOR_EXISTING_USER,
  SET_USER_CONSENT,
  VERIFY_OTP_REQUEST_FAILED,
  VERIFY_OTP_REQUEST_SENT,
  VERIFY_OTP_REQUEST_SUCCEED,
  GET_MODIFIER_AND_FACILITY_DATA_REQUEST_SENT,
  GET_MODIFIER_AND_FACILITY_DATA_REQUEST_SUCCEEDEED,
  GET_MODIFIER_AND_FACILITY_DATA_REQUEST_FAILED
} from "./constants";

import { showToaster } from "common/toasterActions";

export const getLocationProvidersRequestSent = () => ({
  type: GET_LOCATION_PROVIDERS_REQUEST_SENT,
});

export const getLocationProvidersRequestSucceeded = (
  locationProviders,
  procedure,
  generalCardiologists
) => ({
  type: GET_LOCATION_PROVIDERS_REQUEST_SUCCEED,
  locationProviders,
  procedure,
  generalCardiologists,
});

export const getLocationProvidersRequestFailed = () => ({
  type: GET_LOCATION_PROVIDERS_REQUEST_FAILED,
});

export const generateOtpRequestSent = () => ({
  type: GENERATE_OTP_REQUEST_SENT,
});

export const generateOtpRequestSucceeded = () => ({
  type: GENERATE_OTP_REQUEST_SUCCEED,
});

export const generateOtpRequestFailed = () => ({
  type: GENERATE_OTP_REQUEST_FAILED,
});

export const verifyOtpRequestSent = () => ({
  type: VERIFY_OTP_REQUEST_SENT,
});

export const verifyOtpRequestSucceeded = () => ({
  type: VERIFY_OTP_REQUEST_SUCCEED,
});

export const verifyOtpRequestFailed = () => ({
  type: VERIFY_OTP_REQUEST_FAILED,
});

export const createPatientRequestSent = () => ({
  type: CREATE_PATIENT_REQUEST_SENT,
});

export const createPatientRequestSucceeded = () => ({
  type: CREATE_PATIENT_REQUEST_SUCCEED,
});

export const createPatientRequestFailed = () => ({
  type: CREATE_PATIENT_REQUEST_FAILED,
});

export const saveProcedureRequestSent = () => ({
  type: SAVE_PROCEDURE_REQUEST_SENT,
});

export const saveProcedureRequestSucceeded = () => ({
  type: SAVE_PROCEDURE_REQUEST_SUCCEEDED,
});

export const saveProcedureRequestFailed = () => ({
  type: SAVE_PROCEDURE_REQUEST_FAILED,
});

export const getFlowIdRequestSent = () => ({
  type: GET_FLOW_ID_REQUEST_SENT,
});

export const getFlowIdRequestSucceeded = (data) => ({
  type: GET_FLOW_ID_REQUEST_SUCCEED,
  data,
});

export const getFlowIdRequestFailed = () => ({
  type: GET_FLOW_ID_REQUEST_FAILED,
});

export const pageChangeSucceeded = (page) => ({
  type: PAGE_CHANGE,
  page,
});

export const setQrCodeDetails = (procedure, facilityName) => ({
  type: SET_QR_DETAILS,
  procedure,
  facilityName,
});

export const setSignUpDataInStore = (data) => ({
  type: SET_SIGNUP_DATA,
  data,
});

export const setSignUpDataInStoreForExistingUser = (data) => ({
  type: SET_SIGNUP_DATA_FOR_EXISTING_USER,
  data,
});

export const setUserConsentInRedux = (data) => ({
  type: SET_USER_CONSENT,
  data,
});
export const setOtpInReduxState = (data) => ({
  type: SET_EMAIL_OTP_IN_STATE,
  data: data.emailOtp,
});
export const setPhoneOtpInReduxState = (data) => ({
  type: SET_PHONE_OTP_IN_STATE,
  data: data.phoneOtp,
});

export const setProcedureCorrectFlag = (data) => ({
  type: SET_PROCEDURE_CONFIRMATION,
  data: data,
});
export const setExistingUserFlag = (data) => ({
  type: SET_EXISTING_USER_FLAG,
  data: data,
});
export const clearFormData = () => ({
  type: CLEAR_SIGNUP_DATA,
});
export const getModifierAndFaciltyDataRequestSent = () => ({
  type: GET_MODIFIER_AND_FACILITY_DATA_REQUEST_SENT
})
export const getModifierAndFaciltyDataRequestSuccedeed = (data) =>({
  type: GET_MODIFIER_AND_FACILITY_DATA_REQUEST_SUCCEEDEED,
  data
})
export const getModifierAndFaciltyDataRequestFailed = () =>({
  type: GET_MODIFIER_AND_FACILITY_DATA_REQUEST_FAILED,
})


// //////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
// Redux Thunk actions:
// //////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
export const getLocationProviders =
  (locationCode, procedureCode) => (dispatch) => {
    dispatch(getLocationProvidersRequestSent());
    LocationProvidersApiCall(locationCode, procedureCode)
      .then((response) => {
        dispatch(getLocationProvidersRequestSucceeded(response));
      })
      .catch((e) => {
        dispatch(getLocationProvidersRequestFailed());
      });
  };

export const generateOtp =
  (params, nextPage, page, redirect = true, isResend = false) =>
  (dispatch) => {
    dispatch(generateOtpRequestSent());
    GenerateOtpApiCall(params)
      .then((response) => {
        try {
          dispatch(generateOtpRequestSucceeded());
          if (redirect) {
            nextPage(page);
          } else {
            if (isResend) {
              dispatch(showToaster({ message: "OTP Sent Successfully" }));
            }
          }
        } catch (err) {
          dispatch(showToaster({ message: "Fail To Send Otp", type: "error" }));
        }
      })
      .catch((e) => {
        dispatch(generateOtpRequestFailed());
      });
  };

export const verifyOtp = (params, nextPage, page) => (dispatch) => {
  dispatch(verifyOtpRequestSent());
  VerifyOtpApiCall(params)
    .then(() => {
      dispatch(verifyOtpRequestSucceeded());
      nextPage(page);
    })
    .catch((e) => {
      dispatch(verifyOtpRequestFailed());
    });
};

export const createPatient = (params, nextPage, page) => (dispatch) => {
  dispatch(createPatientRequestSent());
  CreatePatientApiCall(params)
    .then((response) => {
      dispatch(createPatientRequestSucceeded());
      nextPage(page);
    })
    .catch((e) => {
      dispatch(createPatientRequestFailed());
    });
};

export const saveProcedure = (params, nextPage, page) => (dispatch) => {
  dispatch(saveProcedureRequestSent());
  saveProcedureApiCall(params)
    .then((response) => {
      dispatch(saveProcedureRequestSucceeded());
      nextPage(page);
    })
    .catch((e) => {
      dispatch(saveProcedureRequestFailed());
    });
};

export const getFlowId = (params) => (dispatch) => {
  dispatch(getFlowIdRequestSent());
  FlowIdApiCall(params)
    .then((response) => {
      dispatch(getFlowIdRequestSucceeded(response.data));
    })
    .catch((e) => {
      dispatch(getFlowIdRequestFailed());
    });
};

export const getModifierAndFacility = (params, nextPage, page) => (dispatch) => {
  dispatch(getModifierAndFaciltyDataRequestSent())
  getModifierApiCall({modifier:params.modifier}, nextPage, page)
  .then((modifierData)=>{
    getFacilityApiCall({code: params.code}, nextPage, page)
    .then((facilityData)=>{
      if((modifierData.data.message !== undefined) || (facilityData.data.message !== undefined)){
        throw new Error(modifierData.data.message || facilityData.data.message)
      }
      else{
        dispatch(getModifierAndFaciltyDataRequestSuccedeed({modifierData: modifierData.data, facilityData: facilityData.data}))
      }
    })
    .catch((e) => {
      dispatch(getModifierAndFaciltyDataRequestFailed());
      dispatch(showToaster({message: e.toString(), type: 'error'}))
    })
  })
}

export const pageChange =
  (page, redirect = true) =>
  (dispatch) => {

    dispatch(pageChangeSucceeded(page));
    //   if (redirect) {
    //     history.push({
    //       pathname: location.pathname,
    //       search: location.search,
    //       hash: `#${page}`,
    //     });
    // };
  };

export const setQRCodedetails = (procedure, facilityName) => (dispatch) => {
  dispatch(setQrCodeDetails(procedure, facilityName));
};

export const setSignUpData = (data, nextPage, page) => (dispatch) => {
  Object.keys(data).forEach((key) => {
    if (typeof data[key] === "string" && data[key]) {
      if (data[key].trim().slice(-1) === ".") {
        data[key] = data[key].trim().substring(0, data[key].length - 2);
      }
    }
  });
  dispatch(setSignUpDataInStore(data));
  dispatch(setUserConsentInRedux());

  nextPage(page);
  // }
  // catch(err){
  //   dispatch(showToaster({ message: "Fail To Save Data", type: "error" }))
  // }
};

export const setSignUpDataForExistingUser = (data, nextPage) => (dispatch) => {
  // try{
  dispatch(setSignUpDataInStoreForExistingUser(data));
  nextPage();
  // }
  // catch(err){
  //   dispatch(showToaster({ message: "Fail To Save Data", type: "error" }))
  // }
};

export const setOtpInState = (data) => (dispatch) => {
  // try{
  dispatch(setOtpInReduxState(data));
  // }
  // catch(err){
  //   dispatch(showToaster({ message: "Fail To Save Otp", type: "error" }))
  // }
};

export const setPhoneOtpInState = (data) => (dispatch) => {
  try {
    dispatch(setPhoneOtpInReduxState(data));
  } catch (err) {
    dispatch(showToaster({ message: "Fail To Save Otp", type: "error" }));
  }
};

export const setProcedureConfirmationFlag = (data) => (dispatch) => {
  dispatch(setProcedureCorrectFlag(data));
};

export const setFlagForExistingUser = (data) => (dispatch) => {
  dispatch(setExistingUserFlag(data));
};

export const clearFormDataInRedux = () => (dispatch) => {
  dispatch(clearFormData());
};
