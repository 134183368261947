import React from "react";
import PropTypes from "prop-types";
import { pluralize } from "utils/stringUtils";
import "./pagination.scss";

export default class Pagination extends React.Component {
  // eslint-disable-line react/prefer-stateless-function
  constructor(props) {
    super(props);

    this.state = {
      pageNumber: (props.pagination && props.pagination.page) || null
    };
  }

  componentDidUpdate(prevProps) {
    if (this.props.pagination.page !== prevProps.pagination.page) {
      this.setState({
        pageNumber: this.props.pagination.page
      });
      if (!this.props.pagination.avoidScroll) {
        window.scrollTo(0, 0);  
      }
    }
  }

  goToPrevPage = () => {
    if (this.props.pagination.prevPage != null) {
      this.props.handlePagination(this.props.pagination.prevPage);
    }
  };

  goToNextPage = () => {
    if (this.props.pagination.nextPage != null) {
      this.props.handlePagination(this.props.pagination.nextPage);
    }
  };

  handleKeyPress = e => {
    if (e.key === "Enter") {
      this.goToPage();
    }
  };

  onPageInputValueChange = (e) => {
    this.setState({
      pageNumber: parseInt(e.target.value),
    });
  }

  handleKeyPress = (e) => {
    if (e.key === 'Enter') {
      this.goToPage();
    }
  }

  goToPage = () => {
    if (this.state.pageNumber && this.state.pageNumber <= this.props.pagination.totalPages && this.state.pageNumber > 0) {
      this.props.handlePagination(this.state.pageNumber)
    }
  }

  render() {
    const { pagination } = this.props;
    const paginationTitle = this.props.title || "Patients";
    return pagination.total > 0 ? (
      <div className="pagination-container">
        <div className="pagination-body-container text-right no-padding">
          <span>{`${paginationTitle} ${pagination.startNumber} - ${pagination.endNumber}`} <i>(of {pagination.total})</i></span>
          <div className="go-to-page">
            <div className="form-group">
              <input
                type="number"
                value={this.state.pageNumber || ""}
                className="form-control"
                onChange={this.onPageInputValueChange}
                onKeyPress={this.handleKeyPress}
              />
            </div>
            <button className="btn btn-primary" onClick={this.goToPage}>
              GO TO PAGE
            </button>
          </div>
          <div
            className={`prev-page page-nav-icon ${
              pagination.prevPage === null ? "disabled" : ""
            }`}
            onClick={this.goToPrevPage}
          >
            <span className="icon icon-font-a-left-chevron"></span>
          </div>
          <div
            className={`next-page page-nav-icon ${
              pagination.nextPage === null ? "disabled" : ""
            }`}
            onClick={this.goToNextPage}
          >
            <span className="icon icon-font-a-right-chevron"></span>
          </div>
        </div>
        <div className="clearfix"></div>
      </div>
    ) : null;
  }
}

Pagination.propTypes = {
  pagination: PropTypes.any,
  handlePagination: PropTypes.func
};
