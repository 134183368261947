import PropTypes from "prop-types";
import React from "react";
import {connect} from "react-redux";

import LightboxModal from "../../components/LightboxModal";
import {generateOtp, getFlowId, pageChange, setQRCodedetails} from "./action";
import AddProcedureVerificationView from "./components/AddProcedureVerificationView";
import DataConfirmView from "./components/DataConfirmView";
import EmailOTPVerifyView from "./components/EmailOTPVerifyView";
import ForgotPasswordView from "./components/ForgotPasswordView";
import LoggedInUserProcedureView from "./components/LoggedInUserProcedureView";
import LoginView from "./components/LoginView";
import MobileOTPVerifyView from "./components/MobileOTPVerifyView";
import NameView from "./components/NameView";
import PasswordResetSuccessView from "./components/PasswordResetSuccessView";
import PasswordView from "./components/PasswordView";
import ProcedureVerificationView from "./components/ProcedureVerificationView";
import SuccessView from "./components/SuccessView";
import WelcomeView from "./components/WelcomeView";
import * as Constants from "./constants";
import {
  DATA_CONFIRM_VIEW,
  FORGOT_PASSWORD_VIEW,
  LOGGED_IN_USER_PROCEDURE_VIEW,
  LOGIN_VIEW,
  NAME_VIEW,
  WELCOME_VIEW
} from "./constants";
import "./patientRegister.scss";
import Wrapper from "./components/Wrapper";

class PatientRegister extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      isLoading: false,
      flowType: props.flowType || "sign_up",
      flowId: props.flowId,
      phone: props.phone,
      isMfaEnabled: this.props.isMfaEnabled,
      existingUser: props.existingUser || false,
      firstName: this.props.firstName,
    };
  }

  goToNextPage = (page) => {
    this.props.handlePageChange(page);
  };

  switchToAddProcedureFlow = () => {
    this.props.handlePageChange(this.props.page + 8);
  };
  switchToSignUpFlow = () => {
    this.props.handlePageChange(this.props.page - 8);
  };

  componentDidMount = () => {
    this.props.setQRCodedetailsInState(
      this.props.match.params.procedure,
      this.props.match.params.facility_name
    );
    this.props.getFlowId({ flow_type: this.state.flowType });
  };

  componentWillReceiveProps = (nextProps) => {
    const formData = {};

    Object.keys(nextProps).forEach((key) => {
      if (key in this.state) {
        if (nextProps[key] !== this.state[key]) {
          formData[key] = nextProps[key];
        }
      }
    });

    if (formData.length) {
      this.setState({ ...formData });
    }

    let data = {};

    if (this.state.flowId !== nextProps.flowId) {
      data["flowId"] = nextProps.flowId;
    }
    if (this.state.isMfaEnabled !== nextProps.isMfaEnabled) {
      data["isMfaEnabled"] = nextProps.isMfaEnabled;
    }

    if (this.state.existingUser !== nextProps.existingUser) {
      data["existingUser"] = nextProps.existingUser;
      if (this.state.firstName !== nextProps.firstName) {
        data["firstName"] = nextProps.firstName;
      }
    }
    this.setState({ ...data });
  };

  renderView = () => {
    if (this.props.page === Constants.NAME_VIEW)
      return (
        <Wrapper
          nextPage={this.goToNextPage}
          prevPage={WELCOME_VIEW}
          view={
            <NameView
              nextPage={this.goToNextPage}
              prevPage={this.goToPrevPage}
            />
          }
          title1="Great!"
          title2="Let’s get started."
        />
      );
    if (this.props.page === Constants.DATA_CONFIRM_VIEW)
      return (
        <Wrapper
          nextPage={this.goToNextPage}
          prevPage={NAME_VIEW}
          view={
            <DataConfirmView
              nextPage={this.goToNextPage}
              prevPage={this.goToPrevPage}
            />
          }
          title1="Is this all correct?"
        />
      );
    if (this.props.page === Constants.EMAIL_VERIFY_VIEW)
      return (
        <Wrapper
          nextPage={this.goToNextPage}
          prevPage={NAME_VIEW}
          view={
            <EmailOTPVerifyView
              nextPage={this.goToNextPage}
              prevPage={this.goToPrevPage}
            />
          }
          title1={`Thanks, ${this.props.firstName}!`}
        />
      );
    if (this.props.page === Constants.PHONE_VERIFY_VIEW)
      return (
        <Wrapper
          nextPage={this.goToNextPage}
          prevPage={NAME_VIEW}
          view={
            <MobileOTPVerifyView
              nextPage={this.goToNextPage}
              prevPage={this.goToPrevPage}
            />
          }
          title1={`Thanks, ${this.props.firstName}!`}
        />
      );
    if (this.props.page === Constants.PASSWORD_VIEW)
      return (
        <Wrapper
          nextPage={this.goToNextPage}
          prevPage={NAME_VIEW}
          view={
            <PasswordView
              nextPage={this.goToNextPage}
              prevPage={this.goToPrevPage}
            />
          }
          title1="Sign Up"
        />
      );
    if (this.props.page === Constants.ADD_PROCEDURE_VERIFICATION_VIEW)
      return (
        <Wrapper
          nextPage={this.goToNextPage}
          prevPage={LOGGED_IN_USER_PROCEDURE_VIEW}
          view={
            <AddProcedureVerificationView
              nextPage={this.goToNextPage}
              prevPage={this.goToPrevPage}
            />
          }
          title1="Verify Procedure"
        />
      );
    if (this.props.page === Constants.PROCEDURE_VERIFICATION_VIEW)
      return (
        <Wrapper
          nextPage={this.goToNextPage}
          prevPage={DATA_CONFIRM_VIEW}
          view={
            <ProcedureVerificationView
              nextPage={this.goToNextPage}
              prevPage={this.goToPrevPage}
            />
          }
          title1="Verify Procedure"
        />
      );

    if (this.props.page === Constants.SUCCESS_VIEW)
      return <SuccessView nextPage={this.goToNextPage} />;
    if (this.props.page === Constants.LOGIN_VIEW)
      return (
        <Wrapper
          nextPage={this.goToNextPage}
          prevPage={WELCOME_VIEW}
          view={
            <LoginView
              nextPage={this.goToNextPage}
              prevPage={this.switchToSignUpFlow}
            />
          }
          title1="Login"
        />
      );
    if (this.props.page === Constants.LOGGED_IN_USER_PROCEDURE_VIEW)
      return (
        <Wrapper
          nextPage={this.goToNextPage}
          prevPage={WELCOME_VIEW}
          view={
            <LoggedInUserProcedureView
              nextPage={this.goToNextPage}
              prevPage={this.switchToSignUpFlow}
            />
          }
          title1="Login"
        />
      );
    if (this.props.page === Constants.FORGOT_PASSWORD_VIEW)
      return (
        <Wrapper
          nextPage={this.goToNextPage}
          prevPage={LOGIN_VIEW}
          view={
            <ForgotPasswordView
              nextPage={this.goToNextPage}
              prevPage={this.switchToSignUpFlow}
            />
          }
          title1="Forgot Password?"
        />
      );
    if (this.props.page === Constants.PASSWORD_RESET_SUCCESS_VIEW)
      return (
        <Wrapper
          nextPage={this.goToNextPage}
          showBanner={true}
          bannerMessage="Please do not close or refresh this page."
          prevPage={FORGOT_PASSWORD_VIEW}
          view={
            <PasswordResetSuccessView
              nextPage={this.goToNextPage}
              prevPage={this.switchToSignUpFlow}
            />
          }
        />
      );
  };

  render() {
    return (
      <>
        <LightboxModal show={this.props.isLoading} />
        {this.props.page === Constants.WELCOME_VIEW ? (
          <WelcomeView
            nextPage={this.goToNextPage}
            changeFlow={this.switchToAddProcedureFlow}
          />
        ) : (
          this.renderView()
        )}
      </>
    );
  }
}

PatientRegister.propTypes = {
  isLoading: PropTypes.bool,
  procedure: PropTypes.string,
  facilityName: PropTypes.string,
  page: PropTypes.number,
  surgeonOptions: PropTypes.any,
  // cardiologistOptions: PropTypes.any,
  // procedure: PropTypes.any,
  fetchLocationProviders: PropTypes.func,
  // generateOtp: PropTypes.func,
  verifyOtp: PropTypes.func,
  // pageChange: PropTypes.func,
  handlePageChange: PropTypes.func,
  requestOtp: PropTypes.func,
  submitPatientDetails: PropTypes.func,
  generalCardiologists: PropTypes.any,
  getFlowId: PropTypes.func,
  isMfaEnabled: PropTypes.bool,
  flowId: PropTypes.string,
  existingUser: PropTypes.bool,
};

const mapStateToProps = (state) => ({
  isLoading:
    state.patientRegister.isLoading ||
    state.currentUser.isLoading ||
    state.forgotPassword.isLoading,
  page: state.patientRegister.page,
  phone: state.patientRegister.phone,
  firstName: state.patientRegister.firstName,
  flowType: state.patientRegister.flowType,
  flowId: state.patientRegister.flowId,
  isMfaEnabled: state.patientRegister.isMfaEnabled,
  existingUser: state.patientRegister.existingUser,
});

const mapDispatchToProps = (dispatch) => ({
  setQRCodedetailsInState: (procedure, facilityName) =>
    dispatch(setQRCodedetails(procedure, facilityName)),
  handlePageChange: (page, redirect = true) =>
    dispatch(pageChange(page, redirect)),
  getFlowId: (params) => dispatch(getFlowId(params)),
  generatePhoneOtp: (params, nextPage, redirect = true) =>
    dispatch(generateOtp(params, nextPage, redirect)),
});

const withConnect = connect(mapStateToProps, mapDispatchToProps);

export default withConnect(PatientRegister);
