import axios from 'axios';
import {
  SHARE_OUTCOMES_REQUEST_MARK_DOWNLOADED_AT,
  SHARE_OUTCOMES_REQUEST_MARK_VIEWED_AT,
  SHOW_SHARE_OUTCOMES_REQUEST,
} from '../constants';

export const showShareOutcomesRequest = (params) => axios.post(SHOW_SHARE_OUTCOMES_REQUEST, params);

export const markShareOutcomesViewedAt = (id, pin) => axios.patch(`${SHARE_OUTCOMES_REQUEST_MARK_VIEWED_AT}/${id}`, { key: "viewed_at", pin });

export const markShareOutcomesDownloadedAt = (id, pin) => axios.patch(`${SHARE_OUTCOMES_REQUEST_MARK_DOWNLOADED_AT}/${id}`, { key: "downloaded_at", pin });
