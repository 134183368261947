import React from 'react';
// import ClearIndicator from './Indicators';
import ValueContainer from './ValueContainer';
import MultiSelect from './MultiSelect';
import Option from './Options';
import Menu from './Menu';

// eslint-disable-next-line react/prefer-stateless-function
export default class MultiSelectPill extends React.Component {
  render() {
    const { className: classes, ...props } = this.props;
    return (
      <MultiSelect
        {...props}
        className={`multi-select-container ${classes || ''}`}
        classNamePrefix="multi-select-pill"
        backspaceRemovesValue={false}
        onMenuClose={() => props.onMenuClose(props.facetName, props.value)}
        onChange={(selected) => props.onChange(props.facetName, selected)}
        components={{
          MultiValue: ValueContainer,
          Option,
          Menu,
        }}
      />
    );
  }
}
