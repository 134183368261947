const STRINGS = {
  EDIT_APPOINTMENT_CONFIRMATION_MODAL:
    "Changing the date/time in this src is not an official re-scheduling of your appointment. If you are seeking to change your appointment, you must contact your provider to do so. Once that step is completed, you may edit the appointment in this src.",
  SELECT_PROCEDURE_NOTE:
    "Please select a procedure to view. You can always select a different procedure at any time at the top of your screen.",
  DID_NOT_RECEIVE_VERIFICATION_CODE: "Didn't receive your code?",
  MSG_ENTER_VERIFICATION_CODE:
    "Please enter the Verification Code sent to mobile number",
  BTN_RESENT_CODE: "Resend Code",
  LBL_EDIT_APPT: "Important:",
  MSG_EDIT_APPT:
    "If your procedure has been scheduled, do not leave it blank. My Journey is most helpful to you if we can give you tasks and reminders in relation to your procedure date.",
  MSG_EDIT_APPT_SMALL:
    "You may ignore if your procedure date/time is yet to be decided.",
  SUMMERY_MSG_CAREGIVER:
    "My Journey allows you to add caregivers, such as family members, to help you throughout your surgical journey. Only add those you trust– they will have full access to your My Journey account.",
  SWITCH_PROCEDURE_CONFIRMATION_MODAL:
    "Are you sure you want to change your procedure view from %procedure1 to %procedure2",
  NO_RESULTS_FOUND_HEADER: 'No results found for "%searchTerm".',
  NO_RESULTS_SUBTEXT1:
    "# Try searching using a different keyword or check your spelling.",
  NO_RESULTS_SUBTEXT2:
    "# You can also create a new question if you didn't find what you were looking for.",
  WELCOME_HEADER: "Welcome to My Journey!",
  WELCOME_HEADER_BODY: "Thank you for signing up",
  WELCOME_MSG_1:
    "To begin, we will need to gather some information relating to your procedure.",
  WELCOME_MSG_2:
    "This will allow us to send you relevant notifications and tasks at specific times throughout your surgical journey.",
  WELCOME_MSG_1_INVITED_USER:
    "Welcome to My Journey, Important updates related to your procedure will appear on the notifications tab.",
  WELCOME_MSG_2_INVITED_USER:
    "Thank you for giving us a chance to serve you - Team My Journey.",
  WELCOME_MSG_FOOTER: "Best of luck!",
  INVITE_CODE_CREATE_PROCEDURE:
    "If you want to create a new procedure, please provide the invite code below.",
  MSG_PROCEDURE_COMPLETED:
    "since your procedure is complete, all the information related to it has been deleted.",
  REQUEST_DOWNLOAD_DATA_INFORMATION:
    "You can download a copy of your 'My Journey' information. Once your data is prepared for download, we will inform you via email/text about the same.",
  PASSWORD_CONSTRAINT_INFORMATION:
    "Password must have at least 10 characters, 1 uppercase, 1 lowercase letter and 1 number.",
  SUPPORT_EMAIL: 'support@test.pelvic.care',
  SUPPORT_PHONE_NUMBER: "1-866-704-4447",
  PROCEDURE_VERIFICATION_PHONE_NUMBER: "1-866-704-4447",
  LOGIN_SCREEN_MESSAGE: "As an Advisor, the My Journey Companion portal gives you visibility and tools to understand the engagement activities of and communicate with consumers using the app.",
  PATIENT_REGISTER_MOBILE_VERIFICATION_PROMPT: "Enter the new verification code sent to:",
  PATIENT_REGISTER_EMAIL_VERIFICATION_PROMPT: "Enter the new verification code sent to:",
};
export default STRINGS;
