import { IconButton } from "@mui/material";
import EditOutlinedIcon from "@mui/icons-material/EditOutlined";
import Stack from "@mui/material/Stack";
import PropTypes from "prop-types";
import React from "react";
import Media from "react-media";
import { connect } from "react-redux";
import PrimaryButton from "../../../components/StyleGuideComponents/PrimaryButton";
import { generateOtp } from "../action";
import { NAME_VIEW, PROCEDURE_VERIFICATION_VIEW } from "../constants";
import DisclaimerView from "./DisclaimerView";
import moment from "moment";
import {DATE_FORMAT} from "../../../constants";

// import { generateOtp, verifyOtp,setUserConsent } from "../action";
class DateConfirmView extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      flowId: props.flowId || null,
      flowType: props.flowType || "sign_up",
      firstName: props.firstName || "",
      lastName: props.lastName || "",
      email: props.email || "",
      phone: props.phone || "",
      addressLine1: props.addressLine1 || "",
      addressLine2: props.addressLine2 || "",
      city: props.city || "",
      zipCode: props.zipCode || "",
      stomaMeasuringAppt: props.stomaMeasuringAppt? moment(new Date(this.props.stomaMeasuringAppt)).format(DATE_FORMAT) : null,
      surgeryDate: props.surgeryDate? moment(new Date(props.surgeryDate)).format(DATE_FORMAT) : null,
      procedure: props.procedure || "",
      facilityName: props.facilityName || "",
      marketingConsent: props.marketingConsent || false,
      open: false,
    };
  }

  componentDidMount() {
    window.scroll({
      top: 0,
      left: 0,
      behavior: "smooth",
    });
  }

  componentWillReceiveProps(nextProps) {
    const formData = {};

    Object.keys(nextProps).forEach((key) => {
      if (key in this.state) {
        if (nextProps[key] !== this.state[key]) {
          formData[key] = nextProps[key];
        }
      }
    });

    if (formData.length) {
      this.setState({ ...formData });
    }
  }

  onAgreeOfDisclaimer = () => {
    this.setState({ open: false }, () => {
      this.handleOTP();
    });
  };

  onDisagreeOfDisclaimer = () => {
    this.setState({ open: false });
  };

  onDisclaimerClose = () => {
    this.setState({ open: false });
  };

  openConsentModal = () => {
    this.setState({ open: true });
  };

  handleOTP = () => {
    this.props.nextPage(PROCEDURE_VERIFICATION_VIEW);
    // const params = {
    //   flow_type: this.state.flowType,
    //   flow_id: this.state.flowId,
    //   contact_number: trimCountryCodeFromPhoneNumber(this.state.phone),
    // };
    // this.props.generatePhoneOtp(params, this.props.nextPage, PHONE_VERIFY_VIEW );
  };
  fullAddress = () => {
    let address = "";
    address =
      address !== ""
        ? this.state.addressLine1
          ? `${address}, ${this.state.addressLine1}`
          : `${address}`
        : `${this.state.addressLine1}`;
    address =
      address !== ""
        ? this.state.addressLine2
          ? `${address}, ${this.state.addressLine2}`
          : `${address}`
        : `${this.state.addressLine2}`;
    address =
      address !== ""
        ? this.state.city
          ? `${address}, ${this.state.city}`
          : `${address}`
        : `${this.state.city}`;
    address =
      address !== ""
        ? this.state.zipCode
          ? `${address}, ${this.state.zipCode}`
          : `${address}`
        : `${this.state.zipCode}`;
    return address;
  };

  render() {
    return (
      <div className="data-confirm-view">
        <Media query={{ maxWidth: 480 }}>
          {(screenIsSmall) =>
            screenIsSmall ? <></> : <hr className="hr-line" />
          }
        </Media>

        <div className="text-left data-confirm-body">
          <Stack spacing={"24px"}>
            <div
              style={{
                display: "flex",
                justifyContent: "space-between",
                alignItems: "center",
              }}
            >
              <Stack spacing={"16px"}>
                <div className="data-verification-title">Full Name</div>

                <div className="data-verification-value">
                  {this.props.firstName} {this.state.lastName}
                </div>
              </Stack>
              <div className="no-padding float-right">
                <IconButton
                  aria-label="toggle password visibility"
                  onClick={() => this.props.nextPage(NAME_VIEW)}
                  label="edit"
                >
                  <EditOutlinedIcon className="edit-data-icon" size="large" />{" "}
                  <span className="edit-data-text">Edit</span>
                </IconButton>
              </div>
            </div>
            <div>
              <Stack spacing={"16px"}>
                <div className="data-verification-title">
                  Mobile Phone Number
                </div>
                <div className="data-verification-value">
                  {this.props.phone}
                </div>
              </Stack>
            </div>
            <div>
              <Stack spacing={"16px"}>
                <div className="data-verification-title">Email Address</div>
                <div className="data-verification-value">
                  {this.state.email}
                </div>
              </Stack>
            </div>
            <div>
              <Stack spacing={"16px"}>
                <div className="data-verification-title">Address</div>
                <div className="data-verification-value">
                  {/* {this.state.addressLine1}, {this.state.addressLine2}, {this.state.city}, {this.state.state}, {this.state.zipCode} */}
                  {this.fullAddress()}
                </div>
              </Stack>
            </div>
            {
              <div>
                <Stack spacing={"16px"}>
                  <div className="data-verification-title">
                    Stoma Measuring Appointment Date
                  </div>
                  <div className="data-verification-value">
                    {this.state.stomaMeasuringAppt ? (
                      this.state.stomaMeasuringAppt
                    ) : (
                      <span className="not-available-data">Not Entered</span>
                    )}
                  </div>
                </Stack>
              </div>
            }
            {
              <div>
                <Stack spacing={"16px"}>
                  <div className="data-verification-title">Surgery Date</div>
                  <div className="data-verification-value">
                    {this.state.surgeryDate ? (
                      this.state.surgeryDate
                    ) : (
                      <span className="not-available-data">Not Entered</span>
                    )}
                  </div>
                </Stack>
              </div>
            }
            <div>
              <Stack spacing={"16px"}>
                <div className="data-verification-title">Procedure</div>
                <div className="data-verification-value">
                  {this.state.procedure}
                </div>
              </Stack>
            </div>
            <div>
              <Stack spacing={"16px"}>
                <div className="data-verification-title">Facility Name</div>
                <div className="data-verification-value">
                  {this.state.facilityName}
                </div>
              </Stack>
            </div>
            <div>
              <Stack sx={{ alignSelf: "center" }} spacing={"16px"}>
                <PrimaryButton
                  sx={{ alignSelf: "center" }}
                  onClick={this.handleOTP}
                  text="Yes, It’s correct"
                  fullWidth
                />
              </Stack>
            </div>
          </Stack>
        </div>
        <DisclaimerView
          open={this.state.open}
          onClose={this.onDisclaimerClose}
          onAgree={this.onAgreeOfDisclaimer}
          onDisagree={this.onDisagreeOfDisclaimer}
        />
      </div>
      //   </div>
      // </div>
    );
  }
}

DateConfirmView.propTypes = {
  flowId: PropTypes.string,
  isLoading: PropTypes.bool,
  firstName: PropTypes.string,
  lastName: PropTypes.string,
  email: PropTypes.string,
  phone: PropTypes.string,
  addressLine1: PropTypes.string,
  addressLine2: PropTypes.string,
  city: PropTypes.string,
  state: PropTypes.string,
  zipCode: PropTypes.string,
  stomaMeasuringAppt: PropTypes.object,
  surgeryDate: PropTypes.object,
  procedure: PropTypes.string,
  facilityName: PropTypes.string,
  marketingConsent: PropTypes.bool,
};

const mapStateToProps = (state) => ({
  firstName: state.patientRegister.firstName,
  lastName: state.patientRegister.lastName,
  email: state.patientRegister.email,
  phone: state.patientRegister.phone,
  addressLine1: state.patientRegister.addressLine1,
  addressLine2: state.patientRegister.addressLine2,
  city: state.patientRegister.city,
  zipCode: state.patientRegister.zipCode,
  stomaMeasuringAppt:
    state.patientRegister.stomaMeasuringAppt,
  surgeryDate: state.patientRegister.surgeryDate,
  procedure: state.patientRegister.procedure,
  facilityName: state.patientRegister.facilityName,
  marketingConsent:
    state.patientRegister.marketingConsent,
  flowType: state.patientRegister.flowType,
  flowId: state.patientRegister.flowId,
});

const mapDispatchToProps = (dispatch) => ({
  generatePhoneOtp: (params, nextPage, page) =>
    dispatch(generateOtp(params, nextPage, page)),
});
const withConnect = connect(mapStateToProps, mapDispatchToProps);

export default withConnect(DateConfirmView);
