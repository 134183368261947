
import {
  SHOW_TOASTER,
  REMOVE_TOASTER,
} from './constants';

import { RESET_GLOBAL_REDUCERS } from '../constants';

export const toasts = (state = [], action) => {
  switch (action.type) {
    case SHOW_TOASTER:
      return state.concat(action.payload);
    case REMOVE_TOASTER:
      return state.filter((item) => item.id !== action.id);
    case RESET_GLOBAL_REDUCERS:
      return [];
    default:
      return state;
  }
};
