import axios from 'axios';

import { XEALTH_V1_PROGRAM } from './constants';

export const getXealthV1Program = (token) => {
  const params = {
    token,
  };
  return axios.get(`${XEALTH_V1_PROGRAM}`, { params });
};
