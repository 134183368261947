import { RESET_GLOBAL_REDUCERS } from "../../../constants";
import * as PassiveModeInfoWithOTPConstants from "../PassiveModeInfoWithOTP/constants";
import * as Constants from "./constants";

const initialState = {
  isLoading: false,
  hasError: false,
  isContentLoading: false,
  isCodeVerificationSuccessful: false,
  metadata: {
    userInfo: null,
    authenticationRequired: false,
    linkKey: null,
  },
  data: {},
};

const PassiveModeReducers = (state = initialState, action) => {
  switch (action.type) {
    case Constants.GET_PASSIVE_MODE_METADATA_REQUEST_SENT:
    case Constants.SUBMIT_TASK_RESPONSE_REQUEST_SENT:
    case Constants.UPDATE_APPOINTMENT_REQUEST_SENT:
    case PassiveModeInfoWithOTPConstants.VERIFY_LOGIN_CODE_REQUEST_SENT:
      return {
        ...state,
        isLoading: true,
      };
    case Constants.GET_PASSIVE_MODE_CONTENT_REQUEST_SENT:
      return {
        ...state,
        isContentLoading: true,
      };
    case Constants.GET_PASSIVE_MODE_METADATA_REQUEST_SUCCEED:
      return {
        ...state,
        isLoading: false,
        metadata: {
          ...state.metadata,
          userInfo: action.data.data,
          authenticationRequired: action.data.authRequired,
          linkKey: action.data.data.linkKey,
        },
      };
    case Constants.GET_PASSIVE_MODE_METADATA_REQUEST_FAILED:
    case Constants.SUBMIT_TASK_RESPONSE_REQUEST_FAILED:
    case PassiveModeInfoWithOTPConstants.VERIFY_LOGIN_CODE_REQUEST_FAILED:
      return {
        ...state,
        isLoading: false,
      };
    case Constants.GET_PASSIVE_MODE_CONTENT_REQUEST_FAILED:
      return {
        ...state,
        isContentLoading: false,
      };
    case Constants.GET_PASSIVE_MODE_CONTENT_REQUEST_SUCCEED:
      return {
        ...state,
        isContentLoading: false,
        data: action.data.data,
      };

    case Constants.SUBMIT_TASK_RESPONSE_REQUEST_SUCCEED:
      return {
        ...state,
        isLoading: false,
      };
    case PassiveModeInfoWithOTPConstants.VERIFY_LOGIN_CODE_REQUEST_SUCCEED:
      return {
        ...state,
        isLoading: false,
        isCodeVerificationSuccessful: true,
      };
    case Constants.UPDATE_APPOINTMENT_REQUEST_SUCCEED:
      return {
        ...state,
        isLoading: false,
        hasError: false,
      };
    case Constants.UPDATE_APPOINTMENT_REQUEST_FAILED:
      return {
        ...state,
        isLoading: false,
        hasError: true,
      };
    case RESET_GLOBAL_REDUCERS:
      return {
        ...state,
        isLoading: false,
        metadata: {
          userInfo: null,
          authenticationRequired: false,
        },
        data: {},
      };
    default:
      return state;
  }
};
export default PassiveModeReducers;
