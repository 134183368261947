import React from 'react';
import PropTypes from 'prop-types';
import { dateTimeStringToMMSDateFormat } from '../../utils/dateUtils';

class NavNotificationCard extends React.PureComponent {
  render() {
    return (
      <div className={`nav-notification-card ${this.props.notification.readOn == null ? 'unread' : ''}`} data-index={this.props.index}>
        <div className="content">
          {`${this.props.notification.subject} - ${this.props.notification.body}`}
        </div>
        <div className="timestamp">
          {dateTimeStringToMMSDateFormat(this.props.notification.sentTime)}
        </div>
      </div>
    );
  }
}

NavNotificationCard.propTypes = {
  notification: PropTypes.object,
  index: PropTypes.any,
};


export default NavNotificationCard;
