export const GET_OSTOMY_INFO_REQUEST_SENT =
  "src/DashboardProfile/GET_OSTOMY_INFO_REQUEST_SENT";
export const GET_OSTOMY_INFO_REQUEST_SUCCEED =
  "src/DashboardProfile/GET_OSTOMY_INFO_REQUEST_SUCCEED";
export const GET_OSTOMY_INFO_REQUEST_FAILED =
  "src/DashboardProfile/GET_OSTOMY_INFO_REQUEST_FAILED";

export const GET_CARE_TEAM_INFO_REQUEST_SENT =
  "src/DashboardProfile/GET_CARE_TEAM_INFO_REQUEST_SENT";
export const GET_CARE_TEAM_INFO_REQUEST_SUCCEED =
  "src/DashboardProfile/GET_CARE_TEAM_INFO_REQUEST_SUCCEED";
export const GET_CARE_TEAM_INFO_REQUEST_FAILED =
  "src/DashboardProfile/GET_CARE_TEAM_INFO_REQUEST_FAILED";

export const GET_PERSONAL_INFO_REQUEST_SENT =
  "src/DashboardProfile/GET_PERSONAL_INFO_REQUEST_SENT";
export const GET_PERSONAL_INFO_REQUEST_SUCCEED =
  "src/DashboardProfile/GET_PERSONAL_INFO_REQUEST_SUCCEED";
export const GET_PERSONAL_INFO_REQUEST_FAILED =
  "src/DashboardProfile/GET_PERSONAL_INFO_REQUEST_FAILED";
