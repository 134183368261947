import { getXealthV1Program } from 'services/xealthV1Program';
import { setAuthHeaders } from 'services/base';
import * as StorageService from 'services/storage';
import camelcaseKeys from 'change-case-object';
import * as Constants from './constants';

export const getXealthProgramSuccess = (data) => ({
  type: Constants.GET_XEALTH_PROGRAM_SUCCESS,
  data,
});

export const getXealthProgramFailed = (data) => ({
  type: Constants.GET_XEALTH_PROGRAM_SUCCESS,
  data,
});


export const getProgram = (token) => ((dispatch) => {
  getXealthV1Program(token)
    .then((response) => {
      setAuthHeaders(response.headers);
      StorageService.persistAuthHeadersInDeviceStorage(response.headers);
      const currentUser = camelcaseKeys.camelCase(response.data.info);
      StorageService.setItemToStorage('currentUser', JSON.stringify(currentUser));
      StorageService.setItemToStorage('xealth', true);
      
      const url = `/patients/${response.data.patient_id}/procedures/${response.data.user_procedure_id}`;
      dispatch(getXealthProgramSuccess(url));
      window.location.href = url; // This needs to redirect to the patient page      
      return null;
    })
    .catch((error) => {
      dispatch(getXealthProgramFailed(error.response && error.response.data ? error.response.data : null));
    });
});
