/**
 *
 * NavigationBar
 *
 */

import LinkContainer from "components/LinkContainer";
import isEmpty from "lodash/isEmpty";
import PropTypes from "prop-types";
import React from "react";
import { Dropdown, Navbar, NavDropdown, NavItem } from "react-bootstrap";
import Media from "react-media";
import { connect } from "react-redux";
import { Link, withRouter } from "react-router-dom";
import { combineReducers, compose } from "redux";
import { getItemFromStorage } from "services/storage";

import { defaultLandingPageRouteGenerator } from "common/authGuard";
import { getDepartmentProvidersReducer } from "common/reducer";
import Lookup from "components/Lookup";
import NavNotificationMenu from "containers/NavNotificationMenu";
import injectReducer from "utils/injectReducer";
import {
  USER_TYPE_CAREGIVER,
  USER_TYPE_PATIENT,
  USER_TYPE_PATIENT_ADVOCATE,
  USER_TYPE_PROVIDER,
} from "../../constants";
import "./navigation.scss";

import DefaultPatientAvatarImage from "../../assets/images/defaultpatient.png";
import DefaultPatientAdvocateAvatarImage from "../../assets/images/defaultpatientadvocate.png";
import DefaultProviderAvatarImage from "../../assets/images/defaultprovider.png";
// import Logo from "../../assets/images/securestart1x.png";
// import Logo from "../../assets/images/my_journey_logo.png";
import Logo from "../../assets/images/medtronic.png";


import { navBarPerformerChangeAction, resetPerformerFilter } from "./actions";
import { navBarSelectedPerformerReducer } from "./reducer";

class NavigationBar extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      // This is added to close the profile dropdown when `Edit Profile` button is clicked
      // The button is outside the scope of `NavDropdown` to detect and close
      showProfileDropdown: false,
    };
  }
  onSignOut = () => {
    if (this.props.navBar && this.props.selectedPerformer) {
      this.props.resetPerformer();
    }
  };

  onPerformerClick = (e) => {
    let performer = null;
    if (e.target.dataset.id !== "") {
      performer = this.props.performers.find(
        (p) => p.id === parseInt(e.target.dataset.id, 10)
      );
    }
    this.props.updateSelectedPerformer(performer);
  };

  getUserProfileName = (userType) => {
    switch (userType) {
      case USER_TYPE_PROVIDER:
        return "Provider";
      case USER_TYPE_PATIENT_ADVOCATE:
        return this.props.userProfileName;
      case USER_TYPE_PATIENT:
        return "Patient";
      case USER_TYPE_CAREGIVER:
        return "Caregiver";
      default:
        return "";
    }
  };

  redirect = () => defaultLandingPageRouteGenerator();

  renderNavNotificationMenu = () => {
    const proxyUser = isEmpty(getItemFromStorage("proxyUser"))
      ? null
      : JSON.parse(getItemFromStorage("proxyUser"));
    const currentUser = isEmpty(getItemFromStorage("currentUser"))
      ? null
      : JSON.parse(getItemFromStorage("currentUser"));
    if (!proxyUser && currentUser && currentUser.type === USER_TYPE_CAREGIVER) {
      return null;
    }
    return <NavNotificationMenu />;
  };

  render() {
    const { userDetails } = this.props;
    const currentUserName = `${this.props.userDetails.firstName} ${this.props.userDetails.lastName}`;
    const avatarImage =
      this.props.userDetails.type === USER_TYPE_PATIENT_ADVOCATE
        ? DefaultPatientAdvocateAvatarImage
        : this.props.userDetails.type === USER_TYPE_PROVIDER
        ? DefaultProviderAvatarImage
        : DefaultPatientAvatarImage;
    const profilePhoto = this.props.userDetails.profilePhotoUrl || avatarImage;
    const userProfileName = this.getUserProfileName(userDetails.type);
    const isPasswordChangeNeeded = !!(
      userDetails !== null && this.props.userDetails.needsPasswordChange
    );
    const userType = userDetails.hasSuperUserPrivileges
      ? `Super ${userProfileName}`
      : userDetails.isSupportUser
      ? `Support ${userProfileName}`
      : userProfileName;
    const isViewDataDownloadRequestComponent =
      this.props.history.location.pathname.includes(
        "view-data-download-request"
      );
    return (
      <>
        <Navbar className="custom-nav-bar">
          {/* <Navbar.Header> */}
          <Navbar.Brand>
            <Link
              to={
                isViewDataDownloadRequestComponent
                  ? this.props.location.pathname
                  : ""
              }
              replace
              onClick={this.redirect}
            >
              <img src={Logo} className="mms-logo" alt="My Journey" />
            </Link>
          </Navbar.Brand>
          {/* </Navbar.Header> */}
          {this.props.isSignedIn && !isViewDataDownloadRequestComponent && (
            <Navbar.Collapse className="custom-dropdown justify-content-end">
              {this.props.isSignedIn &&
                !this.props.isLookup &&
                this.props.userType !== USER_TYPE_PATIENT &&
                this.props.userType !== USER_TYPE_CAREGIVER &&
                !isPasswordChangeNeeded && (
                  <Lookup
                    isSignedIn={this.props.isSignedIn}
                    isLookup={!this.props.isLookup}
                    userType={this.props.userType}
                  />
                )}
              {!isPasswordChangeNeeded &&
                (userDetails.hasSuperUserPrivileges ||
                  userDetails.isSupportUser) &&
                this.props.history.location.pathname === "/" && (
                  <NavDropdown
                    className="nav-dropdown-scrollable nav-notifications"
                    id="basic-nav-dropdown"
                    rootCloseEvent="click"
                    autoClose
                    title={<span className="caret">{`View As: ${
                      this.props.selectedPerformer === null
                        ? "All"
                        : `${this.props.selectedPerformer.firstName} ${this.props.selectedPerformer.lastName}`
                    }`}</span>}
                  >
                    <NavDropdown.Item
                      className="menu-dropdown-link"
                      onClick={this.onPerformerClick}
                      data-id=""
                    >
                      All
                    </NavDropdown.Item>
                    {this.props.performers.map((performer) => (
                      <NavDropdown.Item
                        key={`performer_key_${performer.id}`}
                        className="menu-dropdown-link"
                        onClick={this.onPerformerClick}
                        data-id={performer.id}
                      >
                        {performer.firstName}
                        &nbsp;
                        {performer.lastName}
                      </NavDropdown.Item>
                    ))}
                  </NavDropdown>
                )}
              {!isPasswordChangeNeeded && this.renderNavNotificationMenu()}
              <Media query={{ maxWidth: 767 }}>
                {(matches) =>
                  matches ? (
                    <>
                      {!isPasswordChangeNeeded ? (
                        <LinkContainer
                          to="/profile"
                          style={{ float: "right", marginRight: "5px" }}
                        >
                          <NavItem>
                            <span
                              className="icon icon-font-a-profile primary-text-color"
                              aria-hidden="true"
                            ></span>
                          </NavItem>
                        </LinkContainer>
                      ) : (
                        <NavItem
                          style={{ float: "right", marginTop: "5px" }}
                          onClick={this.onSignOut}
                        >
                          <span className="primary-text-color">Log Out</span>
                        </NavItem>
                      )}
                    </>
                  ) : (
                    <NavDropdown
                      id="basic-nav-dropdown"
                      className="navbar-profile"
                      rootCloseEvent="click"
                      drop="start"
                      autoClose
                      onToggle={(nextShow) => this.setState({ showProfileDropdown: nextShow })}
                      show={this.state.showProfileDropdown}
                      title={
                        <span
                          className="icon icon-font-a-profile primary-text-color"
                          aria-hidden="true"
                        ></span>
                      }
                    >
                      {!isPasswordChangeNeeded && (
                        <>
                          <div className="user-info-container row">
                            <div className="col-4 user-image">
                              <img src={profilePhoto} alt={currentUserName} />
                            </div>
                            <div className="col-8 no-padding user-information">
                              <p className="user-name">{currentUserName}</p>
                              <p className="user-email">
                                {this.props.userDetails.email}
                              </p>
                              <p className="user-type">{userType}</p>
                              {/* <DDMenuItem to="/profile"> */}
                              <button
                                onClick={() => {
                                  this.setState({ showProfileDropdown: false });
                                  this.props.history.push("/profile");
                                }}
                                className="btn btn-primary"
                              >
                                Edit Profile
                              </button>
                              {/* </DDMenuItem> */}
                            </div>
                          </div>
                          <NavDropdown.Divider />
                          <NavDropdown.Item
                            className="menu-dropdown-link"
                            onClick={(e) => {
                              e.preventDefault();
                              this.props.history.push("/change-password");
                            }}
                          >
                            Change Password
                          </NavDropdown.Item>
                        </>
                      )}
                      <NavDropdown.Item
                        className="menu-dropdown-link"
                        onClick={(e) => {
                          e.preventDefault();
                          this.onSignOut();
                          this.props.history.push("/logout");
                        }}
                      >
                        Log Out
                      </NavDropdown.Item>
                    </NavDropdown>
                  )
                }
              </Media>
            </Navbar.Collapse>
          )}
        </Navbar>
      </>
    );
  }
}

NavigationBar.propTypes = {
  userDetails: PropTypes.object,
  userType: PropTypes.any,
  history: PropTypes.object,
  selectedPerformer: PropTypes.object,
  performers: PropTypes.any,
  updateSelectedPerformer: PropTypes.func,
  isSignedIn: PropTypes.bool,
  isLookup: PropTypes.bool,
  navBar: PropTypes.object,
  resetPerformer: PropTypes.func,
  userProfileName: PropTypes.string,
  location: PropTypes.object,
};

const mapStateToProps = (state, ownProps) => ({
  userDetails: isEmpty(JSON.parse(getItemFromStorage("currentUser")))
    ? state.currentUser.attributes
    : JSON.parse(getItemFromStorage("currentUser")),
  userType:
    state.currentUser.attributes.type ||
    (getItemFromStorage("token") !== null && getItemFromStorage("currentUser")
      ? JSON.parse(getItemFromStorage("currentUser")).type
      : null),
  performers: state.navBar.pro.providers,
  selectedPerformer: state.navBar.per.performer,
  navBar: state.navBar,
  isSignedIn:
    state.currentUser.isSignedIn || !isEmpty(getItemFromStorage("token")),
  isLookup: ownProps.history.location.pathname.indexOf("/lookup/") > -1,
  userProfileName: state.currentUser.attributes.userProfileName,
});

const mapDispatchToProps = (dispatch) => ({
  updateSelectedPerformer: (performer) =>
    dispatch(navBarPerformerChangeAction(performer)),
  resetPerformer: () => dispatch(resetPerformerFilter()),
});

const withConnect = connect(mapStateToProps, mapDispatchToProps);

const withReducer = injectReducer({
  key: "navBar",
  reducer: combineReducers({
    pro: getDepartmentProvidersReducer,
    per: navBarSelectedPerformerReducer,
  }),
});

export default withRouter(compose(withReducer, withConnect)(NavigationBar));
