import * as React from "react";
import Button from "@mui/material/Button";
import { PrimaryButtonStyleGuide } from "./StyleGuideCSS";

export default function PrimaryButton(props) {
  const PrimaryButtonStyle = {
    ...props.sx,
    maxWidth: "100%",
    height:"56px",
    width:
      props.fullWidth?("100%"):(props.device === "phone"
      ? props.size === "large"
        ? PrimaryButtonStyleGuide.phone.large.width
        : PrimaryButtonStyleGuide.phone.medium.width
      : props.size === "large"
      ? PrimaryButtonStyleGuide.web.large.width
      : PrimaryButtonStyleGuide.web.medium.width),
    background: PrimaryButtonStyleGuide.background,
    borderRadius: PrimaryButtonStyleGuide.borderRadius,
    fontFamily: PrimaryButtonStyleGuide.fontFamily,
    fontWeight: PrimaryButtonStyleGuide.fontWeight,
    fontSize: PrimaryButtonStyleGuide.fontSize,
    lineHeight: PrimaryButtonStyleGuide.lineHeight,
    letterSpacing: PrimaryButtonStyleGuide.letterSpacing,
    textAlign: PrimaryButtonStyleGuide.align,
    color: PrimaryButtonStyleGuide.color,
    textTransform:"none",

    "&:hover": {
      boxShadow: PrimaryButtonStyleGuide.boxShadow,
      borderRadius: PrimaryButtonStyleGuide.borderRadius,
    },
    "&:active": {
      background: PrimaryButtonStyleGuide.backgroundActive,
    },
    "&:disabled": {
      background: PrimaryButtonStyleGuide.backgroundDisabled,
      color: PrimaryButtonStyleGuide.colorDisabled,
    },
  };

  return (
    <Button
    className="primary-button"
      type={props.submit ? "submit" : "button"}
      disabled={props.disabled}
      disableRipple
      sx={PrimaryButtonStyle}
      onClick={props.onClick}
    >
      {props.text || "primary button"}
    </Button>
  );
}
