export default   {
  fields: [
    "name",
    "title",
    "latitude",
    "longitude",
    "timezone",
    "procedures",
    "code",
  ],
  rules: {
    name: [{ rule: "isRequired", message: "Name is required" }],
    title: [{ rule: "isRequired", message: "Address is required" }],
    latitude: [{ rule: "isRequired", message: "Latitude is required" }],
    longitude: [{ rule: "isRequired", message: "Longitude is required" }],
    timezone: [{ rule: "isRequired", message: "Timezone is required" }],
    procedures: [ { rule: "isRequired", message: "Atleast one Procedure needs to be selected" }],
    code: [{ rule: "isRequired", message: "Code is required" }],
  },
};
