/*
 *
 * CONNECT constants
 *
 */

export const GET_NOTES_REQUEST_SENT = 'src/Connect/GET_NOTES_REQUEST_SENT';
export const GET_NOTES_REQUEST_SUCCEED = 'src/Connect/GET_NOTES_REQUEST_SUCCEED';
export const GET_NOTES_REQUEST_FAILED = 'src/Connect/GET_NOTES_REQUEST_FAILED';

export const POST_CREATE_NOTES_REQUEST_SENT = 'src/Connect/POST_CREATE_NOTES_REQUEST_SENT';
export const POST_CREATE_NOTES_REQUEST_SUCCEED = 'src/Connect/POST_CREATE_NOTES_REQUEST_SUCCEED';
export const POST_CREATE_NOTES_REQUEST_FAILED = 'src/Connect/POST_CREATE_NOTES_REQUEST_FAILED';

export const EMPTY_COMMUNICATION_LOG_MESSAGE = 'Tap on Create Note to create one';
