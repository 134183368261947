import React from 'react';
import SingleSelect from './SingleSelect';

const SingleSelectNoBorder = (props) => {
  const { className: classes } = props;
  return (
    <SingleSelect
      {...props}
      isSearchable={false}
      className={`without-border ${classes || ''}`}
    />
  );
};

export default SingleSelectNoBorder;
