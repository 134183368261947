import validate from "common/validator";
import isEmpty from "lodash/isEmpty";
import PropTypes from "prop-types";
import React from "react";
import { Modal } from "react-bootstrap";
import { connect } from "react-redux";
import { getItemFromStorage } from "services/storage";
import PrimaryButton from "../../../components/StyleGuideComponents/PrimaryButton";
import { generateOtp, verifyOtp } from "../action";
import "../patientRegister.scss";
import { alterForgotPasswordSentFlag } from "../../ForgotPassword/actions";
import { setOtpInState } from "../action";

import { signInUser } from "common/authThunkActions";
import Strings from "../../../strings";

import {
  FORGOT_PASSWORD_VIEW,
  LOGGED_IN_USER_PROCEDURE_VIEW,
  LOGIN_VIEW,
} from "../constants";

class PasswordResetSuccessView extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      flowId: props.flowId || null,
      flowType: props.flowType || "sign_up",
      firstName: props.firstName || "",
      emailOtp: props.emailOtp || "",
      email: props.email || "",
      errors: [],
      phone: props.phone || "",
      zipCode: props.zipCode || "",
      emailOrMobileNumber: "",
      password: "",
      isSignedIn: props.isSignedIn,
      page: props.page,
      showLoginHelpModal: false,
    };
  }

  phoneRule = [
    { rule: "isPhone", message: "Mobile Number or Email is invalid" },
    { rule: "isRequired", message: "Mobile Number or Email is required" },
  ];

  emailRule = [
    { rule: "isEmail", message: "Mobile Number or Email is invalid" },
    { rule: "isRequired", message: "Mobile Number or Email is required" },
  ];

  validationConfig = {
    fields: ["emailOrMobileNumber", "password"],
    rules: {
      emailOrMobileNumber: [
        { rule: "isEmail", message: "Mobile Number or Email is invalid" },
        { rule: "isRequired", message: "Mobile Number or Email is required" },
      ],
      password: [{ rule: "isRequired", message: "Password is required" }],
    },
  };

  handleChange = (name, value) => {
    let errors = this.state.errors;
    delete errors[name];

    this.setState({ [name]: value, errors: errors }, () => {});
  };

  onSubmitClick = () => {
    this.props.nextPage(LOGIN_VIEW);
  };

  onFormValidationSuccess = () => {
    this.setState({
      errors: {},
    });
    const { emailOrMobileNumber, password, otp } = this.state;
    const params = {
      password,
      code: otp,
    };
    if (emailOrMobileNumber.includes("@")) {
      params.email = emailOrMobileNumber;
    } else {
      const contactNumber = emailOrMobileNumber.replace(/[^a-zA-z0-9]/g, "");
      params.contact_number = contactNumber;
    }
    params.has_accepted_terms_and_condition = true;
    this.props.signInUser(params);
  };

  submitForm = (e) => {
    e.preventDefault();
    if (!this.state.emailOrMobileNumber.includes("@")) {
      this.validationConfig.rules.emailOrMobileNumber = this.phoneRule;
    } else {
      this.validationConfig.rules.emailOrMobileNumber = this.emailRule;
    }
    validate(
      this.validationConfig,
      this.state,
      this.onFormValidationFailure,
      this.onFormValidationSuccess
    );
  };

  componentDidMount() {
    this.props.setForgotPasswordSentFlag();
  }

  componentDidUpdate() {
    if (this.state.isSignedIn && this.state.page === 9) {
      this.props.nextPage(LOGGED_IN_USER_PROCEDURE_VIEW);
    }
  }
  componentWillReceiveProps(nextProps) {
    if (nextProps.isSignedIn !== this.state.isSignedIn) {
      this.setState({ isSignedIn: nextProps.isSignedIn });
    }
    if (nextProps.page !== this.state.page) {
      this.setState({ page: nextProps.page });
    }
    if (nextProps.email !== this.state.email) {
      this.setState({ email: nextProps.email });
    }
  }

  // handleChange = (name, value) => {
  //   this.setState({ [name]: value });
  // };

  onValidationFailure = (errors) => {
    this.setState({ errors: errors });
  };

  onForgotPasswordEvent = () => {
    this.props.nextPage(FORGOT_PASSWORD_VIEW);
  };

  onNextValidationSuccess = () => {
    const params = {
      verification_code: this.state.emailOtp,
      flow_type: this.state.flowType,
      flow_id: this.state.flowId,
      email: this.state.email,
    };
    this.props.setOtpInReduxState({ emailOtp: this.state.emailOtp });
    this.props.verifyEmailOtp(params, this.props.nextPage);
    // this.props.nextPage();
  };

  render() {
    return (
      <>
        <Modal
          show={this.state.showLoginHelpModal}
          onHide={() => {
            this.setState({ showLoginHelpModal: false });
          }}
          container={document.body}
          aria-labelledby="contained-modal-title"
          className="primary-modal loginhelp-modal"
        >
           <Modal.Header closeButton closeVariant="white">
            <Modal.Title id="contained-modal-title">Contact Us</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <div className="footer-content-container">
              <div className="text">
                Feel free to contact us via email or phone number.
              </div>
              <div className="text">
                We would be happy to address your queries.
              </div>
              <div className="phone-container">
                <i className="icon icon-font-a-contact"></i>
                <span className="phone">{Strings.SUPPORT_PHONE_NUMBER}</span>
              </div>
              <div className="email-container">
                <i className="icon icon-font-a-email"></i>
                <span className="email">{Strings.SUPPORT_EMAIL}</span>
              </div>
            </div>
          </Modal.Body>
        </Modal>
        <div className="patient-screen-content-container">
          <div className="password-reset-message">
            <div>
              <span className="highlight-text">
                Password reset instructions have been sent to
              </span>{" "}
              <span className="bold-text">{this.state.email}</span>
            </div>
            <br />
            <div>
              Please reset your password and click on{" "}
              <span className="bold-text">continue</span> to go back to login
              screen.
            </div>
          </div>
          <div className="pushed-down-btn-container">
            <PrimaryButton onClick={this.onSubmitClick} text="Continue" />
            <div
              onClick={() => this.setState({ showLoginHelpModal: true })}
              className="login-help-text text-align-center cursor-pointer"
            >
              Login Help?
            </div>
          </div>
        </div>
      </>
    );
  }
}

PasswordResetSuccessView.propTypes = {
  isLoading: PropTypes.bool,
  firstName: PropTypes.string,
  email: PropTypes.string,
  isSignedIn: PropTypes.bool.isRequired,
};

const mapStateToProps = (state) => ({
  firstName:
    state.patientRegister.firstName,
  email:
    state.patientRegister.email,
  phone: state.patientRegister.phone,
  zipCode:
    state.patientRegister.zipCode,
  page: state.patientRegister.page,
  flowType:
    state.patientRegister.flowType,
  flowId: state.patientRegister.flowId,
  isSignedIn:
    state.currentUser.isSignedIn || !isEmpty(getItemFromStorage("token")),
});

const mapDispatchToProps = (dispatch) => ({
  signInUser: (params) => dispatch(signInUser(params)),
  generateEmailOtp: (params, nextPage, redirect = true) =>
    dispatch(generateOtp(params, nextPage, redirect)),
  setOtpInReduxState: (params) => dispatch(setOtpInState(params)),
  generatePhoneOtp: (params, nextPage) =>
    dispatch(generateOtp(params, nextPage)),
  verifyEmailOtp: (params, nextPage) => dispatch(verifyOtp(params, nextPage)),
  setForgotPasswordSentFlag: () => dispatch(alterForgotPasswordSentFlag()),
});
const withConnect = connect(mapStateToProps, mapDispatchToProps);

export default withConnect(PasswordResetSuccessView);
