import React from 'react';
import validate, { clearErrorsForField } from 'common/validator';
import PropTypes from 'prop-types';
import HelpBlock from 'components/HelpBlock';
import AddAttachmentModal from 'containers/AddAttachmentModal';

const validationConfig = {
  fields: ['newMessage'],
  rules: {
    newMessage: [{ rule: 'isRequired', message: 'Message is required' }],
  },
};

class MessageResponse extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      errors: {},
      newMessage: '',
      showAttachmentModal: false,
      showAttachments: true,
    };
  }

  onSendMessage = () => {
    validate(
      validationConfig,
      this.state,
      this.onSendMessageValidationFailure,
      this.onSendMessageValidationSuccess,
    );
  }

  onSendMessageValidationSuccess = () => {
    this.setState({ errors: {} });
    this.props.onSentMessage(this.state.newMessage);
    this.setState({ newMessage: '', showAttachments: false });
  };

  onSendMessageValidationFailure = (errors) => {
    this.setState({ errors });
  };

  changeFieldHandler = (e) => {
    const obj = {};
    obj[e.target.name] = e.target.value;
    obj.errors = clearErrorsForField(this.state.errors, e.target.name);
    this.setState(obj);
  };

  // Attachment Code
  onAddAttachment = () => {
    this.setState({ showAttachmentModal: true, showAttachments: true });
  }

  onAttachmentsAdded = (attachments) => {
    this.setState({
      showAttachmentModal: false,
    });
    this.props.onAttachmentsAdded(attachments);
  }

  onAttachmentRemoved = () => {
    // this.props.onAttachmentRemoved(attachmentId);
  }

  render() {
    return (
      <div className="" style={{ width: '70%' }}>
        <span>Reply</span>
        <div className={`form-group text-reply ${this.state.errors.newMessage !== undefined ? 'has-error' : ''}`}>
          <textarea
            name="newMessage"
            id="newMessage"
            className="form-control"
            placeholder="Type your answer here..."
            value={this.state.newMessage}
            onChange={this.changeFieldHandler}
          />
          <HelpBlock value={this.state.errors.newMessage} />
          <AddAttachmentModal
            onAttachmentsAdded={this.onAttachmentsAdded}
            showModal={this.state.showAttachmentModal}
            onAttachmentRemoved={this.onAttachmentRemoved}
            showAddedAttachments={this.state.showAttachments}
          />
        </div>
        <div style={{ marginTop: '10px' }}>
          <button className="btn btn-primary float-right" onClick={this.onSendMessage}>
            Send Answer
          </button>
          <button className="btn btn-default float-right btn-attachment add-attachment-button" style={{ marginRight: '10px' }} onClick={this.onAddAttachment}>
            Add Attachment
          </button>
        </div>
        <div className="clearfix" />
      </div>
    );
  }
}

MessageResponse.propTypes = {
  onSentMessage: PropTypes.func,
  onAttachmentsAdded: PropTypes.func,
};

export default MessageResponse;
