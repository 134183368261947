import ArrowBackIosIcon from "@mui/icons-material/ArrowBackIos";
import ArrowBackOutlinedIcon from "@mui/icons-material/ArrowBackOutlined";
import React from "react";
import Media from "react-media";
import { connect } from "react-redux";
import title from "./../../../assets/images/securestart3x.png";

class Wrapper extends React.Component {
  render() {
    return (
      <div className="wrapper-outer-body">
        {this.props.showBanner && (
          <div className="wrapper-banner-container">
            {this.props.bannerMessage}
          </div>
        )}

        <div className="wrapper-body">
          <div className="sign-up-header">
            <Media query={{ maxWidth: 480 }}>
              {(screenIsSmall) =>
                screenIsSmall ? (
                  <ArrowBackOutlinedIcon
                    sx={{ width: "24px", height: "24px" }}
                    onClick={() => this.props.nextPage(this.props.prevPage)}
                    style={{ alignSelf: "flex-start" }}
                    fontSize="large"
                  />
                ) : (
                  <ArrowBackIosIcon
                    className="back-icon"
                    sx={{ width: "24px", height: "24px" }}
                    onClick={() => this.props.nextPage(this.props.prevPage)}
                    fontSize="normal"
                  />
                )
              }
            </Media>
            <img className="header-img" alt="" src={title}></img>
          </div>
          {this.props.title1 && (
            <div className="wrapper-title-one">{this.props.title1}</div>
          )}
          {this.props.title2 && (
            <div className="wrapper-title-two">{this.props.title2}</div>
          )}

          <div className="wrapper-inner-body">{this.props.view}</div>
        </div>
      </div>
    );
  }
}

Wrapper.propTypes = {};

const mapStateToProps = (state) => ({});

const mapDispatchToProps = (dispatch) => ({});

const withConnect = connect(mapStateToProps, mapDispatchToProps);

export default withConnect(Wrapper);
