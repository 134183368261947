import axios from "./base";

export const startHeartBeats = (options) => {
  let currentHeartBeats = JSON.parse(localStorage.getItem("currentHeartBeats"));
  if (currentHeartBeats === undefined || currentHeartBeats === null) {
    const currentHeartBeat = [];
    localStorage.setItem("currentHeartBeats", JSON.stringify(currentHeartBeat));
  }
  let heartbeat_time = process.env.REACT_APP_HEARTBEAT_INTERVAL_TIME
    ? process.env.REACT_APP_HEARTBEAT_INTERVAL_TIME
    : 60000;
  let intervalId = setInterval(
    sendHeartBeats.bind(null, options),
    heartbeat_time
  );
  let option = currentHeartBeats.find((option) => option.id === options.id);
  if (!option) {
    options.interval_id.push(intervalId);
    currentHeartBeats.push(options);
    localStorage.setItem(
      "currentHeartBeats",
      JSON.stringify(currentHeartBeats)
    );
  } else {
    currentHeartBeats.pop(option);
    option.interval_id.push(intervalId);
    currentHeartBeats.push(option);
    localStorage.setItem(
      "currentHeartBeats",
      JSON.stringify(currentHeartBeats)
    );
  }
};

export const stopHeartBeats = (options) => {
  let currentHeartBeats = JSON.parse(localStorage.getItem("currentHeartBeats"));
  const option = currentHeartBeats.find((option) => option.id === options.id);
  if (option) {
    option.interval_id.map((inetrval, index) => clearInterval(inetrval));
    currentHeartBeats.pop(option);
    localStorage.setItem(
      "currentHeartBeats",
      JSON.stringify(currentHeartBeats)
    );
  } else if (
    Object.keys(options).length === 0 &&
    options.constructor === Object
  ) {
    for (let currentHeartBeat of currentHeartBeats) {
      for (let inetrval of currentHeartBeat.interval_id) {
        clearInterval(inetrval);
      }
      currentHeartBeats.pop(currentHeartBeat);
    }
    localStorage.setItem(
      "currentHeartBeats",
      JSON.stringify(currentHeartBeats)
    );
  }
};

let sendHeartBeats = (options) => {
  axios
    .get(options.url, {
      params: { options: options },
    })
    .then((result) => {
      //
      if (result.data.shouldEnd) {
        //
        stopHeartBeats(options);
      }
    })
    .catch((error) => {});
};
