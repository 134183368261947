/*
 *
 * Notifications Constants
 *
 */

export const GET_NOTIFICATIONS_REQUEST_SENT = 'src/NavNotificationMenu/GET_NOTIFICATIONS_REQUEST_SENT';
export const GET_NOTIFICATIONS_REQUEST_SUCCEED = 'src/NavNotificationMenu/GET_NOTIFICATIONS_REQUEST_SUCCEED';
export const GET_NOTIFICATIONS_REQUEST_FAILED = 'src/NavNotificationMenu/GET_NOTIFICATIONS_REQUEST_FAILED';

export const GET_UNREAD_NOTIFICATIONS_COUNT_REQUEST_SENT = 'src/NavNotificationMenu/GET_UNREAD_NOTIFICATIONS_COUNT_REQUEST_SENT';
export const GET_UNREAD_NOTIFICATIONS_COUNT_REQUEST_SUCCEED = 'src/NavNotificationMenu/GET_UNREAD_NOTIFICATIONS_COUNT_REQUEST_SUCCEED';
export const GET_UNREAD_NOTIFICATIONS_COUNT_REQUEST_FAILED = 'src/NavNotificationMenu/GET_UNREAD_NOTIFICATIONS_COUNT_REQUEST_FAILED';

export const MARK_UNREAD_NOTIFICATIONS_READ_REQUEST_SENT = 'src/NavNotificationMenu/MARK_UNREAD_NOTIFICATIONS_READ_REQUEST_SENT';
export const MARK_UNREAD_NOTIFICATIONS_READ_REQUEST_SUCCEED = 'src/NavNotificationMenu/MARK_UNREAD_NOTIFICATIONS_READ_REQUEST_SUCCEED';
export const MARK_UNREAD_NOTIFICATIONS_READ_REQUEST_FAILED = 'src/NavNotificationMenu/MARK_UNREAD_NOTIFICATIONS_READ_REQUEST_FAILED';
