/*
 *
 * Connect reducer
 *
 */
import {
  GET_NOTES_REQUEST_SENT,
  GET_NOTES_REQUEST_SUCCEED,
  GET_NOTES_REQUEST_FAILED,
  POST_CREATE_NOTES_REQUEST_SENT,
  POST_CREATE_NOTES_REQUEST_SUCCEED,
  POST_CREATE_NOTES_REQUEST_FAILED,
} from './constants';

const initialState = {
  isLoading: false,
  isCreateNoteRequestInProgress: false,
  notes: [],
};

function patientNotesReducer(state = initialState, action) {
  let newNotes = null;
  switch (action.type) {
    case GET_NOTES_REQUEST_FAILED:
      return {
        ...state,
        isLoading: false,
      };
    case POST_CREATE_NOTES_REQUEST_FAILED:
      return {
        ...state,
        isLoading: false,
        isCreateNoteRequestInProgress: false,
      };
    case POST_CREATE_NOTES_REQUEST_SENT:
      return {
        ...state,
        isCreateNoteRequestInProgress: true,
      };
    case GET_NOTES_REQUEST_SENT:
      return {
        ...state,
        isLoading: true,
      };
    case GET_NOTES_REQUEST_SUCCEED:
      return {
        ...state,
        isLoading: false,
        notes: [
          ...action.data.data],
      };
    case POST_CREATE_NOTES_REQUEST_SUCCEED:
      return {
        ...state,
        isCreateNoteRequestInProgress: false,
      };
    default:
      return state;
  }
}

export default patientNotesReducer;
