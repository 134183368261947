import Visibility from "@mui/icons-material/Visibility";
import VisibilityOff from "@mui/icons-material/VisibilityOff";
import Box from "@mui/material/Box";
import FormControl from "@mui/material/FormControl";
import FormHelperText from "@mui/material/FormHelperText";
import IconButton from "@mui/material/IconButton";
import InputAdornment from "@mui/material/InputAdornment";
import InputLabel from "@mui/material/InputLabel";
import OutlinedInput from "@mui/material/OutlinedInput";
import { ThemeProvider } from "@mui/material/styles";
import { useEffect, useState } from "react";
import "./styleGuideComponents.scss";
import { InputBoxStyleGuide, passwordVisibilityIcon } from "./StyleGuideCSS";
import { theme } from "./Theme";
// import { error } from "shelljs/src/common";

export default function Password(props) {
  const [value, setValue] = useState("");
  const [showPassword, setShowPassword] = useState(false);

  const handleChange = (e) => {
    setValue(e.target.value);
  };

  useEffect(() => {
    if (props.onChange) props.onChange(props.name, value);
  }, [value]);

  const handleClickShowPassword = () => {
    setShowPassword(!showPassword);
  };

  const handleMouseDownPassword = (event) => {
    event.preventDefault();
  };
  return (
    <ThemeProvider theme={theme}>
      <Box>
        <div>
          <FormControl
            sx={{ m: 1, width: "100%", margin: "0" }}
            variant="outlined"
          >
            <InputLabel
              required={props.required}
              sx={{ fontSize: "16px" }}
              htmlFor="outlined-adornment-password"
            >
              {"Password"}
            </InputLabel>

            <OutlinedInput
              error={props.error}
              sx={{
                ...props.sx,
                fontSize: "16px",
              }}
              fullWidth
              size="normal"
              onFocus={
                props.onFocus
                  ? () => {
                      props.onFocus();
                    }
                  : null
              }
              onBlur={
                props.onBlur
                  ? () => {
                      props.onBlur();
                    }
                  : null
              }
              id="outlined-adornment-password"
              type={showPassword ? "text" : "password"}
              value={value}
              onChange={handleChange}
              required={props.required}
              endAdornment={
                value && (
                  <InputAdornment size="normal" position="end">
                    <IconButton
                      aria-label="toggle password visibility"
                      onClick={handleClickShowPassword}
                      onMouseDown={handleMouseDownPassword}
                      edge="end"
                      size="normal"
                    >
                      {showPassword ? (
                        <VisibilityOff
                          sx={{
                            height: passwordVisibilityIcon.height,
                            width: passwordVisibilityIcon.width,
                            color: passwordVisibilityIcon.color,
                          }}
                        />
                      ) : (
                        <Visibility
                          sx={{
                            height: passwordVisibilityIcon.height,
                            width: passwordVisibilityIcon.width,
                            color: passwordVisibilityIcon.color,
                          }}
                        />
                      )}
                    </IconButton>
                  </InputAdornment>
                )
              }
              label={props.password || "Password"}
            />
            <FormHelperText
              id="outlined-weight-helper-text"
              className="no-margin"
            >
              <span
                style={{
                  color: `${InputBoxStyleGuide.colorAlertNegative}`,
                  fontSize: "16px",
                }}
              >
                {props.errorText ? props.errorText : ""}
              </span>
            </FormHelperText>
          </FormControl>
        </div>
      </Box>
    </ThemeProvider>
  );
}
