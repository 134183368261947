import {PHONE_COUNTRY_CODE} from "../constants";

export const pluralize = (string, count) =>
  count === 1 ? string : `${string}s`;

export const getFileNameFromPath = (filePath) =>
  filePath.substring(filePath.lastIndexOf("/") + 1);

export const camelCaseToString = (str, convertToCap = false) => {
  const result = str.replace(/([a-z])([A-Z])/g, "$1 $2");
  if (convertToCap) {
    return capitalize(result);
  }
  return result.toLowerCase();
};

export const capitalize = (str) => str.replace(/\b\w/g, (l) => l.toUpperCase());

export const getFormatedPhoneNumber = (str) => {
  if (str !== "" && str !== null) {
    // Strip all characters from the input except digits
    str = str.replace(/\D/g, "");
    // Trim the remaining str to ten characters, to preserve phone number format
    str = str.substring(0, 10);
    // Based upon the length of the string, we add formatting as necessary
    str = `${str.substring(0, 3)} ${str.substring(3, 10)}`;

    // return str;
    return `${PHONE_COUNTRY_CODE} ${str}`;
  }
  return "";
};


export const trimCountryCodeFromPhoneNumber = (str) => {
  return str.replace(PHONE_COUNTRY_CODE, '').replace(/\s/g,'')
}

export const truncateText = (text, truncationLength, ending = "...") => {
  let truncatedText = "";
  if (text) {
    truncatedText = text.substring(0, truncationLength);
    if (text.length > truncationLength) {
      truncatedText += ending;
    }
  }
  return truncatedText;
};
