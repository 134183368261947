export const GET_PATIENT_PROCEDURES_REQUEST_SENT = 'src/PatientApp/GET_PATIENT_PROCEDURES_REQUEST_SENT';
export const GET_PATIENT_PROCEDURES_REQUEST_SUCCEED = 'src/PatientApp/GET_PATIENT_PROCEDURES_REQUEST_SUCCEED';
export const GET_PATIENT_PROCEDURES_REQUEST_FAILED = 'src/PatientApp/GET_PATIENT_PROCEDURES_REQUEST_FAILED';

export const PUT_SWITCH_ACTIVE_PROCEDURE_SENT = 'src/PatientApp/PUT_SWITCH_ACTIVE_PROCEDURE_SENT';
export const PUT_SWITCH_ACTIVE_PROCEDURE_SUCCEED = 'src/PatientApp/PUT_SWITCH_ACTIVE_PROCEDURE_SUCCEED';
export const PUT_SWITCH_ACTIVE_PROCEDURE_FAILED = 'src/PatientApp/PUT_SWITCH_ACTIVE_PROCEDURE_FAILED';

export const DELETE_PROCEDURE_REQUEST_SENT = 'src/patientApp/Caregivers/DELETE_PROCEDURE_REQUEST_SENT';
export const DELETE_PROCEDURE_REQUEST_SUCCEED = 'src/patientApp/Caregivers/DELETE_PROCEDURE_REQUEST_SUCCEED';
export const DELETE_PROCEDURE_REQUEST_FAILED = 'src/patientApp/Caregivers/DELETE_PROCEDURE_REQUEST_FAILED';

export const VERIFY_INVITE_CODE_REQUEST_SENT = 'src/patientApp/Caregivers/VERIFY_INVITE_CODE_REQUEST_SENT';
export const VERIFY_INVITE_CODE_REQUEST_SUCCEED = 'src/patientApp/Caregivers/VERIFY_INVITE_CODE_REQUEST_SUCCEED';
export const VERIFY_INVITE_CODE_REQUEST_FAILED = 'src/patientApp/Caregivers/VERIFY_INVITE_CODE_REQUEST_FAILED';
export const VERIFY_INVITE_CODE_CLEAR_STATE = 'src/patientApp/Caregivers/VERIFY_INVITE_CODE_CLEAR_STATE';

export const CLEAR_PATIENT_PROCEDURES = 'src/patientApp/CLEAR_PATIENT_PROCEDURES';
