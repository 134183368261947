import {
  POST_SEND_INVITES_REQUEST_SENT,
  POST_SEND_INVITES_REQUEST_SUCCEED,
  POST_SEND_INVITES_REQUEST_FAILED,
  DISCARD_PROCEDURES_REQUEST_SENT,
  DISCARD_PROCEDURES_REQUEST_SUCCEED,
  DISCARD_PROCEDURES_REQUEST_FAILED,
} from './constants';


const initialState = {
  isLoading: false,
};

function patientReducer(state = initialState, action) {
  switch (action.type) {
    case POST_SEND_INVITES_REQUEST_SENT:
    case DISCARD_PROCEDURES_REQUEST_SENT:
      return {
        ...state,
        isLoading: true,
      };
    case POST_SEND_INVITES_REQUEST_SUCCEED:
    case DISCARD_PROCEDURES_REQUEST_SUCCEED:
    case DISCARD_PROCEDURES_REQUEST_FAILED:
    case POST_SEND_INVITES_REQUEST_FAILED:
      return {
        ...state,
        isLoading: false,
      };
    default:
      return state;
  }
}

export default patientReducer;
