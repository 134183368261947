import React from 'react';

const ClearIndicator = (props) => {
  const {
    children = <span className="select-clear-icon icon icon-font-a-clear-all"></span>,
    innerProps: { ref, ...restInnerProps },
  } = props;
  return (
    <span
      {...restInnerProps}
      title="Clear all"
      ref={ref}
    >
      {children}
    </span>
  );
};

export default ClearIndicator;
