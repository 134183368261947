import isEmpty from "lodash/isEmpty";
import queryString from "query-string";
import * as StorageService from "services/storage";
import { isMasquerading } from "utils/common";
import { USER_TYPE_CAREGIVER, USER_TYPE_PATIENT } from "../constants";

import { localHistory as history } from "../index";

export const defaultLandingPageRouteGenerator = (location = null) => {
  const currentUser = StorageService.getCurrentUser();
  let redirectLocation = null;
  let userType = null;
  if (currentUser) {
    userType = currentUser.type;
    // PassiveModeCheck
    if (currentUser && !isEmpty(history.location.search)) {
      redirectLocation = queryString.parse(
        history.location.search
      ).redirect_uri;
      if (redirectLocation) {
        if (currentUser.needsPasswordChange) {
          return `/reset-password?redirect_uri=${redirectLocation}`;
        }
        return redirectLocation;
      }
    }
  } else if (location && location.pathname !== "/") {
    return {
      pathname: "/signin",
      search: `?redirect_uri=${location.pathname}`,
      hash: location.hash,
    };
  } else {
    return { pathname: "/signin" };
  }

  if (currentUser && currentUser.needsPasswordChange) {
    return "/reset-password";
  }

  switch (userType) {
    case USER_TYPE_PATIENT:
      if (StorageService.getActiveCompositeProcedureId()) {
        return `/mydashboard/${StorageService.getActiveCompositeProcedureId()}/procedures/appointments`;
      }
      return "/select-procedure";
    case USER_TYPE_CAREGIVER:
      if (isMasquerading()) {
        if (StorageService.getActiveCompositeProcedureId()) {
          return `/mydashboard/${StorageService.getActiveCompositeProcedureId()}/procedures/appointments`;
        }
        return "/select-procedure";
      }
      return "/select-patient";
    default:
      return "/";
  }
};

export const redirectToMainPage = () =>
  history.replace(defaultLandingPageRouteGenerator());

// Note this will just redirect to sign in page and does not logs out user. If user is already logged in then even after
// calling this, src will redirect to user to respective landing page.
export const redirectToSignIn = () => history.replace("/signin");

export const redirectToLogout = () => history.replace("/logout");

export const redirectToSelectPatient = () => {
  history.replace("/select-patient");
};

export const isValidatedUsersInitialProcessCompleted = (location) => {
  const currentUser = StorageService.getCurrentUser();
  let userType = null;
  if (currentUser) {
    userType = currentUser.type;
  } else {
    return false;
  }

  if (currentUser && currentUser.isPassiveMode) {
    return true;
  }

  if (
    currentUser &&
    currentUser.needsPasswordChange &&
    currentUser.signInCount > 0
  ) {
    if (location.pathname === "/reset-password") {
      return true;
    }
    return false;
  }

  if (
    location.pathname === "/profile" ||
    location.pathname === "/change-password" ||
    location.pathname === "/post-complete-procedure"
  ) {
    return true;
  }
  switch (userType) {
    case USER_TYPE_PATIENT:
      if (StorageService.getActiveCompositeProcedureId()) {
        return true;
      }
      if (location.pathname === "/select-procedure") {
        return true;
      }
      return false;
    case USER_TYPE_CAREGIVER:
      if (location.pathname === "/select-patient") {
        return true;
      }
      if (isMasquerading()) {
        if (StorageService.getActiveCompositeProcedureId()) {
          return true;
        }
        if (location.pathname === "/select-procedure") {
          return true;
        }
        return false;
      }
      return false;
    default:
      return true;
  }
};
