import CancelIcon from "@mui/icons-material/Cancel";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import Stack from "@mui/material/Stack";
import validate from "common/validator";
import PropTypes from "prop-types";
import React from "react";
import { connect } from "react-redux";
import { trimCountryCodeFromPhoneNumber } from "utils/stringUtils";
import Password from "../../../components/StyleGuideComponents/Password";
import PrimaryButton from "../../../components/StyleGuideComponents/PrimaryButton";
import { createPatient } from "../action";
import "../patientRegister.scss";
import { SUCCESS_VIEW } from "../constants";
import { passwordValidationConfig } from "../validators";
import moment from "moment";
import {DATE_FORMAT_DASH_REV} from "../../../constants";

class PasswordView extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      password: "Dilip",
      timer: 6,
      errors: [],
      upperCaseTestHover: false,
      lowerCaseTestHover: false,
      numericalTestHover: false,
      passwordLengthTestHover: false,
      showPasswordValidations: false,
    };
  }

  handleChange = (name, value) => {
    this.setState({ [name]: value }, () => {

    });
  };

  componentDidMount = () => {
    window.scroll({
      top: 0,
      left: 0,
      behavior: "smooth",
    });
  };
  onValidationFailure = (errors) => {
    this.setState({ errors: errors });
  };

  onNextValidationSuccess = () => {
    const params = {
      first_name: this.props.firstName,
      last_name: this.props.lastName,
      email: this.props.email,
      contact_number: trimCountryCodeFromPhoneNumber(this.props.phone)[0] == '0' ? trimCountryCodeFromPhoneNumber(this.props.phone).replace('0','') : trimCountryCodeFromPhoneNumber(this.props.phone),
      location_code: this.props.facilityCode,
      procedure_modifier: this.props.modifierIdentifier,
      procedure_date: this.props.surgeryDate ? moment(new Date(this.props.surgeryDate)).format(DATE_FORMAT_DASH_REV) : null,
      stoma_marking_date: this.props.stomaMeasuringAppointment ? moment(new Date(this.props.stomaMeasuringAppointment)).format(DATE_FORMAT_DASH_REV) : null,
      email_verification_code: this.props.emailOtp,
      contact_number_verification_code: this.props.phoneOtp,
      flow_type: this.props.flowType,
      flow_id: this.props.flowId,
      password: this.state.password,
      password_confirmation: this.state.password,
      has_marketing_consent: this.props.marketingConsent,
      has_user_consented: this.props.hasUserConsented,
      is_procedure_valid: this.props.isProcedureValid,

      address: {
        line_1: this.props.addressLine1,
        line_2: this.props.addressLine2,
        city: this.props.city,
        zipcode: this.props.zipCode,
      },
    };
    //
    this.props.createPatient(params, this.props.nextPage, SUCCESS_VIEW);
  };

  validatePassword = () => {
    validate(
      passwordValidationConfig,
      this.state,
      this.onValidationFailure,
      this.onNextValidationSuccess
    );
  };

  render() {
    return (
      <div className="text-center">
        <Stack spacing={"8px"}>
          <div>
            <div className="dark-highlight otp-message">
              Please set your Password.
            </div>
          </div>

          <Password
            onFocus={() => {
              this.setState({ showPasswordValidations: true });
            }}
            id="password"
            label="Password"
            name="password"
            value={this.state.password}
            onChange={(name, value) => this.handleChange(name, value)}
            required
            error={"password" in this.state.errors}
            errorText={
              "password" in this.state.errors ? this.state.errors.password : ""
            }
          />

          <div
            className={`${
              this.state.showPasswordValidations
                ? "password-tests-visible"
                : "password-tests-hidden"
            } resend-message password-tests`}
          >
            <div className="password-must-message">
              Password must have at least
            </div>

            <div className="resend-code password-verify-check-box-wrapper">
              <div className="password-verify-check-box-inner-wrapper">
                <div className="password-verify-check-box">
                  {/[A-Z]/.test(this.state.password) ? (
                    <CheckCircleIcon
                      className="password-test-icon"
                      fontSize="large"
                      sx={{ color: "#34C759" }}
                    />
                  ) : (
                    <CancelIcon fontSize="large" sx={{ color: "#DB5461" }} />
                  )}
                  <span>1 uppercase</span>
                </div>
                <div className="password-verify-check-box">
                  {/[a-z]/.test(this.state.password) ? (
                    <CheckCircleIcon
                      fontSize="large"
                      sx={{ color: "#34C759" }}
                    />
                  ) : (
                    <CancelIcon fontSize="large" sx={{ color: "#DB5461" }} />
                  )}
                  <span>1 lowercase</span>
                </div>
              </div>

              <div className="password-verify-check-box-inner-wrapper">
                <div className="password-verify-check-box">
                  {/[0-9]/.test(this.state.password) ? (
                    <CheckCircleIcon
                      fontSize="large"
                      sx={{ color: "#34C759" }}
                    />
                  ) : (
                    <CancelIcon fontSize="large" sx={{ color: "#DB5461" }} />
                  )}
                  <span>1 number</span>
                </div>
                <div className="password-verify-check-box">
                  {this.state.password.length >= 10 ? (
                    <CheckCircleIcon
                      fontSize="large"
                      sx={{ color: "#34C759" }}
                    />
                  ) : (
                    <CancelIcon fontSize="large" sx={{ color: "#DB5461" }} />
                  )}
                  <span>10 characters</span>
                </div>
              </div>
            </div>
          </div>

          <PrimaryButton
            disabled={this.state.password.length <= 0}
            fullWidth
            text="Set Password"
            onClick={this.validatePassword}
          />
        </Stack>
      </div>
    );
  }
}

PasswordView.propTypes = {
  isLoading: PropTypes.bool,
};

const mapStateToProps = (state) => ({
  firstName: state.patientRegister.firstName,
  lastName: state.patientRegister.lastName,
  email: state.patientRegister.email,
  phone: state.patientRegister.phone,
  addressLine1: state.patientRegister.addressLine1,
  addressLine2: state.patientRegister.addressLine2,
  city: state.patientRegister.city,
  zipCode: state.patientRegister.zipCode,
  stomaMeasuringAppointment:
    state.patientRegister.stomaMeasuringAppt,
  surgeryDate: state.patientRegister.surgeryDate,
  modifierIdentifier:
    state.patientRegister.modifierIdentifier,
  facilityCode: state.patientRegister.facilityCode,
  emailOtp: state.patientRegister.emailOtp,
  phoneOtp: state.patientRegister.phoneOtp,
  flowType: state.patientRegister.flowType,
  flowId: state.patientRegister.flowId,
  hasUserConsented:
    state.patientRegister.hasUserConsented,
  isProcedureValid:
    state.patientRegister.isProcedureValid,
  marketingConsent:
    state.patientRegister.marketingConsent,
});

const mapDispatchToProps = (dispatch) => ({
  createPatient: (params, nextPage, page) =>
    dispatch(createPatient(params, nextPage, page)),
});
const withConnect = connect(mapStateToProps, mapDispatchToProps);

export default withConnect(PasswordView);
