/**
 *
 * NavNotificationMenu
 *
 */

import PropTypes from "prop-types";
import React from "react";
import { NavDropdown, NavItem } from "react-bootstrap";
import Media from "react-media";
import { connect } from "react-redux";
import { compose } from "redux";
import LinkContainer from "components/LinkContainer";
import injectReducer from "utils/injectReducer";

import { withRouter } from "react-router-dom";
import { getNotifications, getUnreadNotificationsCount } from "./actions";
import "./index.scss";
import NotificationDropdown from "./NotificationDropdown";
import { notificationsReducer } from "./reducer";

class NavNotificationMenu extends React.Component {
  // eslint-disable-line react/prefer-stateless-function
  constructor(props) {
    super(props);

    this.state = {
      page: 1,
    };
  }

  componentDidMount() {
    this.refreshInterval = setInterval(this.props.refreshUnreadCount, 30000);
  }

  componentWillReceiveProps(nextProps) {
    this.setState({
      page: nextProps.page,
    });
  }

  componentWillUnmount() {
    clearInterval(this.refreshInterval);
  }

  onSelect = (e) => {
    this.setState(
      {
        page: 1,
      },
      this.queryNotifications
    );
  };

  handleScroll = () => {
    if (!this.props.isLast && !this.props.isLoading) {
      this.setState(
        {
          page: this.state.page + 1,
        },
        this.queryNotifications
      );
    }
  };

  queryNotifications = () => {
    this.props.fetchNotifications({ page: this.state.page, limit: 10 });
  };

  seeAllClick = () => {
    if (this.props.notifications.length > 0) {
      this.props.history.push("/notifications");
    }
  };

  render() {
    // Hide notification icon when procedure is completed.
    if (
      this.props.isProcedureCompleted ||
      this.props.isProfileInfoLoading ||
      this.props.isProfileRequestFailed
    ) {
      return null;
    }
    return (
      <Media query={{ maxWidth: 767 }}>
        {(matches) =>
          matches ? (
            <LinkContainer to="/notifications" style={{ float: "right" }}>
              <NavItem
              >
                <span className="nav-notification-icon">
                  <span
                    className="icon icon-font-a-notification-unfilled primary-text-color"
                    aria-hidden="true"
                  ></span>
                  {this.props.unreadCount > 0 && (
                    <span className="pending-notifications-badge">
                      {this.props.unreadCount}
                    </span>
                  )}
                </span>
              </NavItem>
            </LinkContainer>
          ) : (
            <NavDropdown
              id="basic-nav-dropdown"
              className="notification-navbar"
              rootCloseEvent="click"
              autoClose
              drop="start"
              onClick={this.onSelect}
              title={
                <span className="nav-notification-icon">
                  <span
                    className="icon icon-font-a-notification-unfilled primary-text-color"
                    aria-hidden="true"
                  ></span>
                  {this.props.unreadCount > 0 && (
                    <span className="pending-notifications-badge">
                      {this.props.unreadCount}
                    </span>
                  )}
                </span>
              }
            >
              <NotificationDropdown
                handleScroll={this.handleScroll}
                notifications={this.props.notifications}
                isLoading={this.props.isLoading}
              />
            </NavDropdown>
          )
        }
      </Media>
    );
  }
}

NavNotificationMenu.propTypes = {
  isLoading: PropTypes.bool,
  notifications: PropTypes.array,
  refreshUnreadCount: PropTypes.func,
  page: PropTypes.any,
  isLast: PropTypes.any,
  fetchNotifications: PropTypes.func,
  history: PropTypes.object,
  unreadCount: PropTypes.number,
  isProcedureCompleted: PropTypes.bool,
  isProfileInfoLoading: PropTypes.bool,
  isProfileRequestFailed: PropTypes.bool,
};

const mapStateToProps = (state) => ({
  isLoading: state.notificationsDropdown.isLoading,
  notifications: state.notificationsDropdown.notifications,
  unreadCount: state.notificationsDropdown.unreadCount,
  total: state.notificationsDropdown.total,
  page: state.notificationsDropdown.page,
  isLast: state.notificationsDropdown.isLast,
  isProcedureCompleted:
    state.profile.userDetails && state.profile.userDetails.info
      ? state.profile.userDetails.info.isProcedureCompleted
      : false,
  isProfileInfoLoading: state.profile.isRequestInProgress,
  isProfileRequestFailed: state.profile.isRequestFailed,
});

const mapDispatchToProps = (dispatch) => ({
  fetchNotifications: (params) => dispatch(getNotifications(params)),
  refreshUnreadCount: () => dispatch(getUnreadNotificationsCount()),
});

const withConnect = connect(mapStateToProps, mapDispatchToProps);

const withReducer = injectReducer({
  key: "notificationsDropdown",
  reducer: notificationsReducer,
});

export default compose(
  withReducer,
  withRouter,
  withConnect
)(NavNotificationMenu);
