import { isiOS } from 'utils/common';

export const USER_TYPE_PATIENT = 'Patient';

export const USER_TYPE_PROVIDER = 'Provider';

export const USER_TYPE_PATIENT_ADVOCATE = 'PatientAdvocate';

export const USER_TYPE_CAREGIVER = 'Caregiver';

export const RESET_GLOBAL_REDUCERS = 'RESET_GLOBAL_REDUCERS';

export const SEARCHABLE_QUESTION_QUERY_LENGTH = 3;


export const IMAGE_FILE_FORMATS = ['image/jpg', 'image/bmp', 'image/gif', 'image/png', 'image/jpeg', 'image/tiff'];

export const DOC_FILE_FORMATS = ['application/vnd.openxmlformats-officedocument.presentationml.presentation', 'application/vnd.openxmlformats-officedocument.wordprocessingml.document', 'application/pdf', 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet', 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet', 'application/x-msi'];

export const AUDIO_FORMATS = ['audio/x-ms-wma', 'audio/mpeg', 'audio/mp4', 'audio/wavpack'];

export const MS_DOC_EXTENSIONS = ['application/msword', 'application/vnd.ms-excel', 'application/vnd.ms-powerpoint'];

export const ALL_FILE_FORMATS = IMAGE_FILE_FORMATS.concat(DOC_FILE_FORMATS).concat(AUDIO_FORMATS).concat(MS_DOC_EXTENSIONS);

export const COMMUNICATION_LOG_SUPPORTED_FILE_FORMATS = isiOS ? '*/*' : ALL_FILE_FORMATS.toString();

export const DATE_FORMAT = "DD/MM/YYYY";

// YYYY/MM/DD
export const DATE_FORMAT_REV = DATE_FORMAT
  .split("/")
  .reverse()
  .join("/");

export const DATE_FORMAT_DASH = "DD-MM-YYYY";

// YYYY-MM-DD
export const DATE_FORMAT_DASH_REV = DATE_FORMAT_DASH
  .split("-")
  .reverse()
  .join("-");

// Format for DesktopDatePicker MUI component
// That thing has its own format requirements - such a pain.
export const DDP_DATE_FORMAT = "dd/MM/yyyy";

export const TIME_FORMAT = "HH:mm A";

export const TIME_FORMAT_WSECOND = "HH:mm:ss";

export const TIME_FORMAT_ZONE = "HH:mm A z";

export const DATE_FORMAT_STRING = "DD MMM, YYYY";

export const MONTH_YEAR_FORMAT_STRING = "MMM YYYY";

export const MONTH_FULL_YEAR_FORMAT_STRING = "MMMM YYYY";

const DAY_FORMAT = "ddd";

// ddd DD MMM
export const DATE_DAY_WITHOUT_YEAR_FORMAT_STRING = `${DAY_FORMAT}, DD MMM`;

// DD/MM/YYYY HH:MM A
export const DATE_TIME_FORMAT = `${DATE_FORMAT} ${TIME_FORMAT}`;

// ddd DD MMM YYYY
export const DATE_DAY_FORMAT_STRING = `${DAY_FORMAT}, ${DATE_FORMAT_STRING}`;

// ddd DD/MM/YYYY HH:MM A
export const DATE_TIME_DAY_FORMAT = `${DAY_FORMAT}, ${DATE_TIME_FORMAT}`;

// ddd DD MMM YYYY HH:MM A
export const DATE_TIME_DAY_FORMAT_STRING = `${DAY_FORMAT}, ${DATE_FORMAT_STRING} ${TIME_FORMAT}`;

export const PHONE_COUNTRY_CODE = "+1";

export const PHONE_NUMBER_MASK = `${PHONE_COUNTRY_CODE} 9999 9999999`;

export const PHONE_NUMBER_MASK_WO_CC = "9999 9999999";
