import React from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { compose, combineReducers } from "redux";
import injectReducer from "utils/injectReducer";
import LightboxModal from "components/LightboxModal";
import { Modal } from "react-bootstrap";
import { getDepartmentProceduresRequest } from 'common/actions';
import {
  SingleSelectWithoutBorder as CustomizedSelect,
  MultiSelect as Select
} from "components/SelectV2";

import HelpBlock from "components/HelpBlock";
import validationConfig from "./validator";
import validate, { clearErrorsForField } from "common/validator";
import {
  onfetchAllLocationsOfTheDepartmentRequest,
  onAddNewLocationRequest,
  updateLocationAction,
  onfetchTimezonesRequest,
} from "./actions";

import { locationAdministrationReducer } from "./reducer";
import { getDepartmentProceduresReducer } from 'common/reducer';
import Pagination from 'components/Pagination';
import { getItemFromStorage } from 'services/storage';
import { Tab, Nav } from 'react-bootstrap';
import LinkContainer from "components/LinkContainer";
import Emptyview from 'components/Emptyview';
import NoLocations from '../../assets/images/no_locations.png';

class LocationAdministration extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      showAddEditLocationModal: false,
      name: "",
      title: "",
      latitude: "",
      longitude: "",
      timezone: "",
      code: "",
      errors: {},
      procedures: [],
      disableTimezone: false,
      disableCode: false,
      page: 1,
      filterName: "",
      previousFilterData: {
        previousPage: 1,
        previousFilterName: "",
      }
    };
  }

  componentDidMount = () => {
    const currentUser = JSON.parse(getItemFromStorage('currentUser'));
    if (!(currentUser.hasSuperUserPrivileges && currentUser.isAdmin)) {
      this.props.history.push('/not-found');
    }
    else {
      this.props.fetchAllLocationsOfTheDepartment({ 'page': this.state.page });
      this.props.fetchTimezones();
      this.props.getDepartmentProceduresRequest();
    }
  };

  componentDidUpdate(prevProps, prevState) {
    if (
      this.props.isRequestInProgress === false &&
      prevProps.isRequestInProgress === true
    ) {
      this.setState({
        showAddEditLocationModal: this.props.show,
      });
    }
  }

  componentWillReceiveProps = (nextProps) => {
    if (this.props.procedures !== nextProps.procedures) {
      this.setState({
        procedures: nextProps.procedures,
      })
    }
  }

  onAddEditLocationModal = (location) => {
    const {
      id,
      name,
      title,
      latitude,
      longitude,
      timezone,
      code,
    } = location || {};

    const selectedProcedures = this.getSelectedProcedures(id)

    this.setState({
      locationId: id ? id : null,
      name: name ? name : "",
      title: title ? title : "",
      latitude: latitude ? latitude : "",
      longitude: longitude ? longitude : "",
      timezone: timezone ? { label: `${timezone}`, value: `${timezone}` } : "",
      code: code ? code : "",
      showAddEditLocationModal: true,
      procedures: selectedProcedures ? selectedProcedures : [],
      disableTimezone: timezone ? true : false,
      disableCode: code ? true : false,
    });
  };

  getSelectedProcedures = (id) => {
    const selectedLocation = this.props.locations.find((item) => item.id === id);

    const selectedItems = selectedLocation && selectedLocation.procedures.map((item) => ({
      value: item.id,
      label: item.name,
    }));
    return selectedItems;
  }

  hideAddEditLocationModal = () => {
    this.setState({
      showAddEditLocationModal: false,
      errors: {},
    });
  };

  changeFieldHandler = (e) => {
    const obj = {};
    obj[e.target.name] = e.target.value;
    obj.errors = clearErrorsForField(this.state.errors, e.target.name);
    this.setState(obj);
  };

  changeCodeFieldHandler = (e) => {
    const obj = {};
    obj[e.target.name] = e.target.value.toUpperCase();
    obj.errors = clearErrorsForField(this.state.errors, e.target.name);
    this.setState(obj);
  };

  selectTimezone = (selectedOption) => {
    this.setState({
      timezone: selectedOption,
      errors: clearErrorsForField(this.state.errors, "timezone"),
    });
  }

  isFilterUpdated = (currentData, previousData) => {
    return (previousData.previousPage !== currentData.page) ||
      (currentData.filterName.trim().toUpperCase() !== previousData.previousFilterName.trim().toUpperCase()) 
  }

  selectProcedures = (selectedValues) => {
    this.setState({ procedures: selectedValues, errors: clearErrorsForField(this.state.errors, "procedures"), });
  }

  onAddLocation = () => {
    this.validateAddEditLocationFormValues();
  };

  onEditLocation = () => {
    this.validateAddEditLocationFormValues();
  };

  handlePagination = (page) => {
    this.setState({ page }, () => {
      this.onSetFilter(true);
    });
  }


  validateAddEditLocationFormValues = () => {
    validate(
        validationConfig,
        this.state,
        this.onFormValidationFailure,
        this.onFormValidationSuccess
      );
  };

  onFormValidationFailure = (errors) => {
    this.setState({ errors });
  };

  onFormValidationSuccess = () => {
    const params = {
      name: this.state.name,
      title: this.state.title,
      latitude: this.state.latitude,
      longitude: this.state.longitude,
      timezone: this.state.timezone.value,
      code: this.state.code,
      pagination: {
        page: this.state.page,
        name: this.state.filterName,
      }
    };

    if (this.state.procedures) {
      params.procedure_ids = this.extractIdsFromObjects(this.state.procedures)
    }

    if (this.state.locationId) {
      params.id = this.state.locationId;
      this.props.updateLocation(params);
    } else {
      this.props.addLocation(params);
    }
  };


  changeFilterName = (e) => {
    this.setState({ filterName: e.target.value });
  }

  extractIdsFromObjects = (objects) => objects.map((item) => item.value)

  onSetFilter = (isPagination) => {
    const {
      filterName
    } = this.state;

    const page = isPagination ? this.state.page : 1;

    let currentData = { filterName, 'page': this.state.page }
    if (this.isFilterUpdated(currentData, this.state.previousFilterData)) {
      this.props.fetchAllLocationsOfTheDepartment({ 'page': page, name: filterName});
      this.setState({
        page: page,
        previousFilterData: {
          previousPage: this.state.page,
          previousFilterName: this.state.filterName,
        }
      })
    }
  }

  onResetFilter = () => {
    this.setState({
      filterName: '',
      page: 1,
      errors: {},
      previousFilterData: {
        previousPage: 1,
        previousFilterName: '',
      }
    }, () => {
      this.props.fetchAllLocationsOfTheDepartment({ 'page': this.state.page })
    });
  }

  renderFilters = () => {
    return (
      <div className="filters-container">
        <div className="filters-label">
          Filters
        </div>
        <div className="row filter-wrapper">

          <div className="col-xs-12">
            <label htmlFor="">Facility Name</label>
            <input
              type="text"
              className="form-control"
              placeholder="Type Name here"
              onChange={this.changeFilterName}
              value={this.state.filterName}
              name="filterName"
            />
          </div>
          <div className="col-xs-12 btn-wrapper">
            <button
              className={`btn btn-default clear-all ${this.state.isFilterApplied ? '' : 'cursor-pointer'}`}
              onClick={this.onResetFilter}
            >
              Clear All
            </button>
            <button
              className="btn btn-primary apply-button"
              onClick={() => this.onSetFilter(false)}
            >
              Apply Filters
            </button>
          </div>
        </div>
      </div>
    );
  }


  renderData = () => {
    return (
      <>
        {this.props.locations.length > 0 ?
          <div className="row">
          <div className="col-sm-12">
            <div className="ui-table">
              <table className="table">
                <thead className="sticky-header">
                  <tr>
                    <th>Facility Name</th>
                    <th>Address</th>
                    <th>Timezone</th>
                    <th>Self Signup Code</th>
                    <th></th>
                  </tr>
                </thead>
                <tbody>
                {
                    this.props.locations.map((location) => (
                      <tr key={location.id}>
                        <td>{location.name}</td>
                        <td>{location.title}</td>
                        <td>{location.timezone}</td>
                        <td>{location.code}</td>
                        <td>
                          <span className="icon icon-font-a-write-message cursor-pointer" onClick={() => this.onAddEditLocationModal(location)} />
                        </td>
                      </tr>
                    ))
                  }
                </tbody>
              </table>
              </div>
              <div style={{ marginTop: '20px' }}>
                {this.props.pagination &&
                  <Pagination pagination={this.props.pagination} title="Treatment Centers" handlePagination={this.handlePagination} />
                }
              </div>
            </div>
          </div>
          :
          <Emptyview imgSrc={NoLocations} className="" message="No Treatment Centers" />
        }
      </>
    )
  }

  renderChildComponents = () => {
    return (
      <React.Fragment>
        <div className="row">
          <div className="col-sm-3 col-md-3 col-lg-3 filter-container-wrapper" style={{ position: 'fixed' }}>
            {this.renderFilters()}
          </div>
          <div className="col-sm-9 col-md-9 col-lg-9 record-list-wrapper" style={{ float: 'right' }}>
            <div className="col-sm-12 col-md-12 col-lg-12" style={{marginBottom:'25px'}}>
              <span className="add-new-link" onClick={this.onAddEditLocationModal}>
                <span className="icon icon-font-a-add-new-task"></span>
                Add New Location
              </span>
              <span className="pagination">
                {this.props.pagination &&
                  <Pagination pagination={this.props.pagination} title="Locations" handlePagination={this.handlePagination} />
                }
              </span>
            </div>
            <div className="clr"></div>
            {this.renderData()}
          </div>
        </div>
      </React.Fragment>)
  }

  render() {
    return (
      <>
        {this.props.manageLocationsEnabled &&
          <div className="col-xs-12 content-administration">
            <div className="admin-title-container container-fluid">
              <LightboxModal show={ this.props.isRequestInProgress } />

              <Tab.Container id="admin-activity-tabs">
                <React.Fragment>
                  <div className='admin-navigation-div'>
                    <div className="admin-navigation-section">
                      <Nav justify bsStyle="pills">
                        <LinkContainer to="/users" replace>
                          <i className="icon icon-font-a-patient-under-physician"></i>
                          <span className="icon-label">Users</span>
                        </LinkContainer>
                        <LinkContainer to="/locations" replace as="li" className="active">
                          <i className="icon icon-font-a-location"></i>
                          <span className="icon-label">Treatment Centers</span>
                        </LinkContainer>
                      </Nav>
                    </div>
                  </div>
                  <div className="tab-content-wrapper">
                    <Tab.Content>
                      {
                        this.renderChildComponents()
                      }
                    </Tab.Content>
                  </div>
                </React.Fragment>
              </Tab.Container>
              <Modal
                show={this.state.showAddEditLocationModal}
                onHide={this.hideAddEditLocationModal}
                container={document.body}
                autoFocus
                aria-labelledby="contained-modal-title"
                backdrop="static"
              >
                <Modal.Header closeButton>
                  <Modal.Title id="contained-modal-title">
                    {this.state.locationId ? "Edit Location" : "Add Location"}
                  </Modal.Title>
                </Modal.Header>
                <Modal.Body>
                  <div id="add-location-modal">
                    <div
                      className={`form-group ${this.state.errors.name !== undefined ? "has-error" : ""
                        }`}
                    >
                      <label htmlFor="">Facility Name</label>
                      <input
                        type="text"
                        className="form-control"
                        name="name"
                        value={this.state.name}
                        onChange={this.changeFieldHandler}
                      />
                      <HelpBlock value={this.state.errors.name} />
                    </div>

                    <div
                      className={`form-group ${this.state.errors.title !== undefined ? "has-error" : ""
                        }`}
                    >
                      <label htmlFor="">Address</label>
                      <input
                        type="text"
                        className="form-control"
                        name="title"
                        value={this.state.title}
                        onChange={this.changeFieldHandler}
                      />
                      <HelpBlock value={this.state.errors.title} />
                    </div>

                    <div
                      className={`form-group ${this.state.errors.latitude !== undefined ? "has-error" : ""
                        }`}
                    >
                      <label htmlFor="">Latitude</label>
                      <input
                        type="number"
                        className="form-control no-spinner"
                        name="latitude"
                        value={this.state.latitude}
                        onChange={this.changeFieldHandler}
                      />
                      <HelpBlock value={this.state.errors.latitude} />
                    </div>

                    <div
                      className={`form-group ${this.state.errors.longitude !== undefined ? "has-error" : ""
                        }`}
                    >
                      <label htmlFor="">Longitude</label>
                      <input
                        type="number"
                        className="form-control no-spinner"
                        name="longitude"
                        value={this.state.longitude}
                        onChange={this.changeFieldHandler}
                      />
                      <HelpBlock value={this.state.errors.longitude} />
                    </div>

                    <div
                      className={`form-group ${this.state.errors.timezone !== undefined ? "has-error" : ""
                        }`}
                    >
                      <label htmlFor="">Timezone</label>
                      <CustomizedSelect
                        id="select-timezone"
                        placeholder="Select Timezone"
                        onChange={this.selectTimezone}
                        isDisabled={this.state.disableTimezone}
                        value={this.state.timezone}
                        options={Object.keys(this.props.timezones).map((key) => ({
                          value: this.props.timezones[key],
                          label: this.props.timezones[key],
                        }))}
                      />
                      <HelpBlock value={this.state.errors.timezone} />
                    </div>
                    <div
                      className={`form-group ${this.state.errors.code !== undefined ? "has-error" : ""
                        }`}
                    >
                      <label htmlFor="">Self Sign-up Code</label>
                      <input
                        type="text"
                        className="form-control"
                        name="code"
                        readOnly={this.state.disableCode}
                        value={this.state.code}
                        onChange={this.changeCodeFieldHandler}
                      />
                      <HelpBlock value={this.state.errors.code} />
                    </div>
                    <div
                      className={`form-group ${this.state.errors.procedures !== undefined ? "has-error" : ""
                        }`}
                    >
                      <label htmlFor="procedures">Procedures</label>
                      <Select
                        id="select-procedures"
                        placeholder=""
                        onChange={this.selectProcedures}
                        value={this.state.procedures}
                        options={
                          this.props.procedures.map((item) => ({
                            value: item.id, label: `${item.name}`,
                          }))
                        }
                      />
                      <HelpBlock value={this.state.errors.procedures} />
                    </div>
                  </div>
                </Modal.Body>
                <Modal.Footer>
                  {this.state.locationId ? (
                    <button className='btn btn-primary'  onClick={this.onEditLocation}>
                      Save
                    </button>
                  ) : (
                    <button className='btn btn-primary' onClick={this.onAddLocation}>
                      Save
                    </button>
                  )}
                </Modal.Footer>
              </Modal>
            </div>
          </div>
        }
      </>
    );
  }
}
LocationAdministration.propTypes = {
  fetchAllLocationsOfTheDepartment: PropTypes.func,
  pagination: PropTypes.object,
};
const mapStateToProps = (state) => ({
  locations: state.locationAdministration.locationAdministrationReducer.locations,
  isRequestInProgress: state.locationAdministration.locationAdministrationReducer.isRequestInProgress,
  show: state.locationAdministration.locationAdministrationReducer.show,
  locations: state.locationAdministration.locationAdministrationReducer.locations,
  error: state.locationAdministration.locationAdministrationReducer.error,
  timezones: state.locationAdministration.locationAdministrationReducer.timezones,
  procedures: state.locationAdministration.getDepartmentProceduresReducer.procedures,
  manageLocationsEnabled: state.currentUser.attributes.hasSuperUserPrivileges && state.currentUser.attributes.isAdmin,
  pagination: state.locationAdministration.locationAdministrationReducer.pagination,
});

const mapDispatchToProps = (dispatch) => ({
  fetchAllLocationsOfTheDepartment: (page = null) =>
    dispatch(onfetchAllLocationsOfTheDepartmentRequest(page)),
  addLocation: (params) => dispatch(onAddNewLocationRequest(params)),
  updateLocation: (params) => dispatch(updateLocationAction(params)),
  fetchTimezones: () => dispatch(onfetchTimezonesRequest()),
  getDepartmentProceduresRequest: () => dispatch(getDepartmentProceduresRequest()),
});

const withConnect = connect(mapStateToProps, mapDispatchToProps);

const withReducer = injectReducer({
  key: "locationAdministration",
  reducer: combineReducers({
    locationAdministrationReducer,
    getDepartmentProceduresReducer,
  }),
});

export default compose(withReducer, withConnect)(LocationAdministration);
