export const GET_ALL_USERS_OF_THE_DEPARTMENT_REQUEST_SENT =
  "GET_ALL_USERS_OF_THE_DEPARTMENT_REQUEST_SENT";
export const GET_ALL_USERS_OF_THE_DEPARTMENT_REQUEST_SUCCEED =
  "GET_ALL_USERS_OF_THE_DEPARTMENT_REQUEST_SUCCEED";
export const GET_ALL_USERS_OF_THE_DEPARTMENT_REQUEST_FAILED =
  "GET_ALL_USERS_OF_THE_DEPARTMENT_REQUEST_FAILED";

export const ADD_NEW_PROVIDER_REQUEST_SENT =
  'ADD_NEW_PROVIDER_REQUEST_SENT';
export const ADD_NEW_PROVIDER_REQUEST_SUCCEED =
  'ADD_NEW_PROVIDER_REQUEST_SUCCEED';
export const ADD_NEW_PROVIDER_REQUEST_FAILED =
  'ADD_NEW_PROVIDER_REQUEST_FAILED';

export const UPDATE_PROVIDER_INFO_REQUEST_SENT =
  'UPDATE_PROVIDER_INFO_REQUEST_SENT';
export const UPDATE_PROVIDER_INFO_REQUEST_SUCCEED =
  'UPDATE_PROVIDER_INFO_REQUEST_SUCCEED';
export const UPDATE_PROVIDER_INFO_REQUEST_FAILED =
  'UPDATE_PROVIDER_INFO_REQUEST_FAILED';

export const GET_ALL_PERFORMERS_OF_USER_TYPE_MASTER_REQUEST_SENT =
  "GET_ALL_PERFORMERS_OF_USER_TYPE_MASTER_REQUEST_SENT";
export const GET_ALL_PERFORMERS_OF_USER_TYPE_MASTER_REQUEST_SUCCEED =
  "GET_ALL_PERFORMERS_OF_USER_TYPE_MASTER_REQUEST_SUCCEED";
export const GET_ALL_PERFORMERS_OF_USER_TYPE_MASTER_REQUEST_FAILED =
  "GET_ALL_PERFORMERS_OF_USER_TYPE_MASTER_REQUEST_FAILED";

export const PROVIDER_ACCOUNT_STATUS_ISACTIVE_CHANGE_SENT = 
  "PROVIDER_ACCOUNT_STATUS_CHANGE_SENT"
export const PROVIDER_ACCOUNT_STATUS_ISACTIVE_CHANGE_SUCCEED = 
  "PROVIDER_ACCOUNT_STATUS_CHANGE_SUCCEED"
export const PROVIDER_ACCOUNT_STATUS_ISACTIVE_CHANGE_FAILED = 
  "PROVIDER_ACCOUNT_STATUS_CHANGE_FAILED"
