import { useCallback, useEffect, useRef } from "react";
import { Link } from "react-router-dom";
import EmptyView from "components/Emptyview";
import NoNotifications from "../../assets/images/nonotifications.png";
import NavNotificationCard from "./NavNotificationCard";
import LoadingIndicator from "components/LoadingIndicator";

const NotificationDropdown = ({
  handleScroll: propsHandleScroll,
  notifications,
  isLoading,
}) => {
  const notificationRef = useRef();

  const handleScroll = useCallback(() => {
    notificationRef.current.scrollTop ===
      notificationRef.current.scrollHeight -
        notificationRef.current.offsetHeight && propsHandleScroll();
  }, [propsHandleScroll]);

  useEffect(() => {
    const node = notificationRef.current;

    node && node.addEventListener("scroll", handleScroll);

    return () => {
      node && node.removeEventListener("scroll", handleScroll);
    };
  }, [propsHandleScroll, handleScroll]);

  return (
    <div className="nav-notifications-container clearfix">
      <div className="nav-dropdown-header d-flex">
        <div className="nav-dropdown-title">Notifications</div>
        <div className="text-end flex-fill">
          {notifications.length === 0 ? (
            <span className="see-all-link disabled">See all</span>
          ) : (
            <Link className="see-all-link" to="/notifications">
              See all
            </Link>
          )}
        </div>
      </div>
      <div className="nav-dropdown-body" ref={notificationRef}>
        {notifications.length === 0 && (
          <div className="nav-notifications-empty-container">
            <EmptyView
              imgSrc={NoNotifications}
              className=""
              message="No Notifications"
            />
          </div>
        )}
        {notifications.map((notification, index) => (
          <NavNotificationCard key={index} notification={notification} />
        ))}
        {isLoading && (
          <div>
            <LoadingIndicator />
          </div>
        )}
      </div>
    </div>
  );
};

export default NotificationDropdown;
