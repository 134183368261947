import React, {useEffect} from "react";
import {useParams} from "react-router-dom";
import welcome3x from "assets/images/welcome@3x.png";
import welcome2x from "assets/images/welcome@2x.png";
import welcome1x from "assets/images/welcome@1x.png";
import PrimaryButton from "../../../components/StyleGuideComponents/PrimaryButton";
import SecondaryButton from "../../../components/StyleGuideComponents/SecondaryButton";
import "../patientRegister.scss";
import Media from "react-media";
import PropTypes from "prop-types";
import {useDispatch, useSelector} from "react-redux";
import {clearFormDataInRedux, getModifierAndFacility, setFlagForExistingUser,} from "../action";
import {signOutUser} from "common/authThunkActions";
import UserCookieConsent from "../../../components/UserCookieConsent";

import {LOGIN_VIEW, NAME_VIEW, WELCOME_VIEW} from "../constants";

const WelcomeView = (props) => {
  const dispatch = useDispatch();
  const patientRegisterStore = useSelector((store) => store.patientRegister);
  const currentUserStore = useSelector((store) => store.currentUser);
  const params = useParams();

  useEffect(() => {
    dispatch(
      getModifierAndFacility(
        {
          modifier: patientRegisterStore.modifierIdentifier || params.procedure,
          code: patientRegisterStore.facilityCode || params.facility_name,
        },
        props.nextPage,
        WELCOME_VIEW
      )
    );
  }, []);

  // Check if user is signed in and sign them out
  useEffect(() => {
    dispatch(clearFormDataInRedux());
    if (currentUserStore.isSignedIn) {
      dispatch(signOutUser());
    }
  }, [currentUserStore.isSignedIn]);

  return (
    <div className="signup-background">
      <div className="image-container">
        <Media query={{ maxWidth: 991 }}>
          {(screenIsSmall) =>
            screenIsSmall ? (
              <Media query={{ maxWidth: 480 }}>
                {(screenIsSmall) =>
                  screenIsSmall ? (
                    <img
                      src={welcome1x}
                      className="hero-image"
                      alt="welcome"
                    ></img>
                  ) : (
                    <img
                      src={welcome2x}
                      className="hero-image"
                      alt="welcome"
                    ></img>
                  )
                }
              </Media>
            ) : (
              <img src={welcome3x} className="hero-image" alt="welcome"></img>
            )
          }
        </Media>
      </div>
      <div className="text-container-wrapper">
        <div className="welcome-info-container">
          <div className="welcome-info">
            <span className="secure-start-title-text">
              My Journey <sup>SM</sup> Services
            </span>
          </div>
          <div className="welcomeSubTitleText">
            <span>Ostomy Journey Companion App</span>
          </div>
          <p className="secure-start-help-info">
            Hi there! At My Journey<sup>SM</sup> Services, we’re here to help
            support you on your journey - from hospital to home and beyond.
            Let’s get started.
          </p>
          <div className="welcome-btn-container">
            <PrimaryButton
              onClick={() => {
                dispatch(setFlagForExistingUser(false));
                props.nextPage(NAME_VIEW);
              }}
              disabled={!patientRegisterStore.isValidQR}
              text="I am a new app user"
            />
            <SecondaryButton
              onClick={() => {
                dispatch(setFlagForExistingUser(true));
                props.nextPage(LOGIN_VIEW);
              }}
              disabled={!patientRegisterStore.isValidQR}
              text="I have an existing app account"
            />
          </div>
        </div>
      </div>
      <UserCookieConsent />
    </div>
  );
};

WelcomeView.propTypes = {
  isLoading: PropTypes.bool,
  firstName: PropTypes.string,
  email: PropTypes.string,
  signOut: PropTypes.func,
};

export default WelcomeView;

