
import {
  SHOW_TOASTER,
  REMOVE_TOASTER,
} from './constants';

export const showToaster = (data) => ({
  type: SHOW_TOASTER,
  payload: createToaster(data),
});


export const removeToaster = (id) => ({
  type: REMOVE_TOASTER,
  id,
});

let id = 0;
function createToaster(options) {
  const properties = {
    type: options.type || 'success',
    id: id += 1,
    message: options.message || 'Something went wrong!',
    timeout: options.timeout || 3000,
  };
  return properties;
}
