import { showToaster } from 'common/toasterActions';
import { verifyLoginCode, initiateLoginWithOtp } from 'services/passiveMode';
import { setAuthHeaders } from 'services/base';
import * as StorageService from 'services/storage';
import camelcaseKeys from 'change-case-object';
import * as Constants from './constants';
import history from '../../../configureHistory';

export const verifyLoginCodeRequestSent = () => ({
  type: Constants.VERIFY_LOGIN_CODE_REQUEST_SENT,
});

export const verifyLoginCodeRequestSucceeded = () => ({
  type: Constants.VERIFY_LOGIN_CODE_REQUEST_SUCCEED,
});

export const verifyLoginCodeRequestFailed = (data) => ({
  type: Constants.VERIFY_LOGIN_CODE_REQUEST_FAILED,
  data,
});

export const getVerificationCodeSent = () => ({
  type: Constants.GET_VERIFICATION_CODE_REQUEST_SENT,
});

export const getVerificationCodeSucceeded = () => ({
  type: Constants.GET_VERIFICATION_CODE_REQUEST_SUCCEED,
});

export const getVerificationCodeFailed = (data) => ({
  type: Constants.GET_VERIFICATION_CODE_REQUEST_FAILED,
  data,
});

export const getVerificationCode = (linkKey) => ((dispatch) => {
  dispatch(getVerificationCodeSent());
  initiateLoginWithOtp(linkKey)
    .then(() => {
      dispatch(getVerificationCodeSucceeded());
    })
    .catch((error) => {
      dispatch(getVerificationCodeFailed(error.response && error.response.data ? error.response.data : null));
    });
});

export const verifyCode = (linkKey, code, hasAcceptedTnC, redirectUri) => ((dispatch) => {
  dispatch(verifyLoginCodeRequestSent());
  const params = { link_key: linkKey, code, has_accepted_tnc: hasAcceptedTnC };
  verifyLoginCode(params)
    .then((response) => {
      dispatch(verifyLoginCodeRequestSucceeded());
      StorageService.deleteAuthHeadersFromDeviceStorage();
      StorageService.clearDefaultStorage();
      setAuthHeaders(response.headers);
      StorageService.persistAuthHeadersInDeviceStorage(response.headers);
      if (response.data.is_masquerading) {
        StorageService.persistProxyAuthHeadersInDeviceStorage(camelcaseKeys.camelCase(response.headers));
        StorageService.setItemToStorage('proxyUser', JSON.stringify(camelcaseKeys.camelCase(response.data.patient.info)));
      }
      history.replace(redirectUri);
    })
    .catch((error) => {
      dispatch(verifyLoginCodeRequestFailed(error.response && error.response.data ? error.response.data : null));
      dispatch(showToaster({ message: error.response.data.error, type: 'error' }));
    });
});
