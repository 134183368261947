import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { compose } from 'redux';
import { connect } from 'react-redux';
import injectReducer from 'utils/injectReducer';
import { dateWithTimeStringToMMSDateFormat, dateStringToCustomDate } from 'utils/dateUtils';
import DownloadFile from 'components/DownloadFile';
import { downloadFile } from 'services/patient/downloadData';
import PassiveModeFooter from 'patientApp/components/PassiveModeFooter';
import FloatingLabelInput from 'components/FloatingLabelInput';
import LightboxModal from 'components/LightboxModal';
import './styles.scss';
import validate from 'common/validator';
import HelpBlock from 'components/HelpBlock';
import viewDataDownloadRequestReducer from './reducers';
import { verifyPinRequest } from './actions';
import { STATUSES } from './constants';

const validationConfig = {
  fields: ['pin'],
  rules: {
    pin: [{ rule: 'isRequired', message: 'PIN is required' }],
  },
};

class ViewDataDownloadRequest extends Component {
  constructor(props) {
    super(props);
    this.state = {
      errors: {},
      key: '',
      pin: '',
    };
  }

  componentDidMount() {
    this.setState({
      key: this.props.match.params.key,
    });
  }

  onPinChange = (e) => {
    this.setState({
      pin: e.target.value.trim(),
    });
  }

  onContinue = (event) => {
    event.preventDefault();
    validate(
      validationConfig,
      this.state,
      this.onFormValidationFailure,
      this.onFormValidationSuccess
    );
  }

  onFormValidationFailure = (errors) => {
    this.setState({ errors });
  };

  onFormValidationSuccess = () => {
    this.setState({
      errors: {},
    });
    this.props.verifyPin(this.state.key, this.state.pin);
  }

  renderStatusText = (status) => {
    let textString = null;
    switch (status) {
      case STATUSES.PREPARED:
        textString = 'Your data is ready';
        break;
      case STATUSES.REQUESTED:
        textString = 'Your data is being prepared for download';
        break;
      case STATUSES.INPROGESS:
        textString = 'Your data is being prepared for download';
        break;
      case STATUSES.FAILED:
        textString = 'Something went wrong';
        break;
      default:
        textString = null;
    }
    return textString ? <div>{textString}</div> : null;
  }

  renderDataDownloadRequest(data) {
    return (
      <div className="view-data-download-request-container">
        <div className="col-xs-12">
          {
            data !== null ? (
              <React.Fragment>
                <div className="welcome-header">
                  {
                    this.renderStatusText(data.status)
                  }
                </div>
                {
                  data.status === STATUSES.PREPARED ? (
                    <React.Fragment>
                      <div className="row request-data-status">
                        <div className="data-container">
                          <span className="lbl-name">Requested on:&nbsp;</span>
                          <span className="value">{dateWithTimeStringToMMSDateFormat(data.created_at)}</span>
                        </div>
                        <div className="data-container">
                          <span className="lbl-name">Generated on:&nbsp;</span>
                          <span className="value">{dateWithTimeStringToMMSDateFormat(data.created_at)}</span>
                        </div>
                        <div className="data-container">
                          <DownloadFile serviceCall={downloadFile}>
                            <button className="btn btn-primary download">Download Data File &nbsp;</button>
                          </DownloadFile>
                          <div className="expiring-text">{`(Expiring on: ${dateStringToCustomDate(data.expired_at)})`}</div>
                        </div>
                      </div>
                    </React.Fragment>
                  ) : null
                }
              </React.Fragment>
            ) : null
          }
        </div>
        <PassiveModeFooter />
      </div>
    );
  }

  render() {
    if (this.props.isLoading) {
      return <LightboxModal show={this.props.isLoading} />;
    }

    if (this.props.dataDownloadRequest) {
      return this.renderDataDownloadRequest(this.props.dataDownloadRequest);
    }

    return (
      <div className="view-data-download-request-container">
        <div className="welcome-header">
          Please Enter Your PIN code:
          &nbsp;
        </div>
        <div className="login-form-view text-center">
          <div className="">
            <br />
            We sent a PIN code to your email / mobile number:
          </div>
          <form name="form" onSubmit={this.onContinue}>
            <div
              className={`form-group pos-rel ${this.state.errors.verificationCode !== undefined ? 'has-error' : ''}`}
            >
              <FloatingLabelInput
                type="text"
                className="form-control"
                value={this.state.pin}
                label="PIN"
                name="pin"
                onChange={this.onPinChange}
              />
              <HelpBlock value={this.state.errors.pin} />
            </div>

            <div className="clearfix"></div>

            <div className="text-center">
              <button
                className="btn btn-primary"
                value="Continue"
              >
                Continue
              </button>
            </div>
          </form>
        </div>
        <PassiveModeFooter />
      </div>
    );
  }
}

ViewDataDownloadRequest.propTypes = {
  isLoading: PropTypes.bool,
  match: PropTypes.object,
  verifyPin: PropTypes.func,
  dataDownloadRequest: PropTypes.object,
};

const mapStateToProps = (state, props) => ({
  isLoading: state.viewDataDownloadRequest.isLoading,
  dataDownloadRequest: state.viewDataDownloadRequest.dataDownloadRequest,
});

const mapDispatchToProps = (dispatch) => ({
  verifyPin: (key, pin) => dispatch(verifyPinRequest(key, pin)),
});

const withConnect = connect(mapStateToProps, mapDispatchToProps);

const withReducer = injectReducer({
  key: 'viewDataDownloadRequest',
  reducer: viewDataDownloadRequestReducer,
});

export default compose(
  withReducer,
  withConnect,
)(ViewDataDownloadRequest);
