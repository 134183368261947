import React from "react";
import Stack from "@mui/material/Stack";
import Checkbox from "@mui/material/Checkbox";
import FormControlLabel from "@mui/material/FormControlLabel";
import PrimaryButton from "./../../../components/StyleGuideComponents/PrimaryButton";
import InputTextField from "./../../../components/StyleGuideComponents/InputTextField";
import DatePicker from "./../../../components/StyleGuideComponents/DatePicker";
import PropTypes from "prop-types";
import {connect} from "react-redux";
import {compose} from "redux";
import validate from "common/validator";
import {signUpDataValidation} from "../validators";
import {setSignUpData, setUserConsentInRedux} from "../action";
import { validateZipCode } from "../../../services/patientDetails";
import DisclaimerView from "./DisclaimerView";
import {DATA_CONFIRM_VIEW} from "../constants";
import {withRouter} from 'react-router';
import {colors} from '../../../components/StyleGuideComponents/StyleGuideCSS'
import {getPrivacyNoticeUrl} from "common/authThunkActions";
import {PHONE_COUNTRY_CODE} from "../../../constants";

class NameView extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      firstName: props.firstName || "",
      lastName: props.lastName || "",
      email: props.email || "",
      phone: props.phone || "",
      addressLine1: props.addressLine1 || "",
      addressLine2: props.addressLine2 || "",
      city: props.city || "",
      zipCode: props.zipCode || "",
      stomaMeasuringAppt: props.stomaMeasuringAppt || null,
      surgeryDate: props.surgeryDate || null,

      modifierIdentifier:
        props.modifierIdentifier || props.match.params.procedure,
      procedure: props.procedure || "",
      facilityCode: props.facilityCode || props.match.params.facility_name,
      facilityName: props.facilityName || "",

      marketingConsent: props.marketingConsent || false,
      serviceEnrollment: props.serviceEnrollment || false,
      errors: [],
      serviceEnrollmentModal: false,
      nextButtonDisabled: true,
      privacyNoticeUrl: ""
    };
  }

  componentWillReceiveProps = (nextProps) => {
    const formData = {};
    Object.keys(nextProps).forEach((key) => {
      if (key in this.state) {

        if (nextProps[key] !== this.state[key]) {
          formData[key] = nextProps[key];
        }
      }
    });
    if (Object.keys(formData).length) {
      this.setState({ ...formData });
    }
  };

  componentDidMount= () => {
    window.scroll({
      top: 0,
      left: 0,
      behavior: 'smooth'
    });
    this.props.getPrivacyNoticeUrl()
  }

  restrictCharacters = (valueToInsert) => {
    let asciiValue = valueToInsert.slice(-1).charCodeAt(0)
    if(asciiValue > 47 && asciiValue < 58){
      this.setState({phone: valueToInsert})
    }
    else{
      this.setState({phone: valueToInsert.substring(0,valueToInsert.length-1)})
    }
  }

  restrictCharacters = (value) => {
    let asciiValue = value.slice(-1).charCodeAt(0)
    return (asciiValue > 47 && asciiValue < 58) ? value : value.substring(0,value.length-1);
  }

  appendCountryCode = (value) => {
    return `${PHONE_COUNTRY_CODE}value`
  }

  handleSubmit = async (e) => {
    e.preventDefault();
    const initErrors = {};

    try {
      const { data } = await validateZipCode(this.state.zipCode.toUpperCase());

      if (!data.valid) {
        initErrors.zipCode = "Postcode is invalid";
      }
    } catch (error) {
      initErrors.zipCode = "Postcode is invalid";
    }

    validate(
      signUpDataValidation,
      this.state,
      this.onValidationFailure,
      this.onNextValidationSuccess,
      initErrors,
    );
  };

  handleChange = (name, value) => {
    let errors = this.state.errors
    delete errors[name]
    this.setState({ [name]: value,errors:errors });
  };

  handleDateChange = (key, value) => {

    this.setState({ [key]: value });
  };

  onValidationFailure = (errors) => {
    this.setState({ errors: errors });
  };

  onNextValidationSuccess = () => {
    if(!this.state.serviceEnrollment){
      this.setState({ serviceEnrollmentModal: true,nextButtonDisabled:true });
    }
    else{
      this.props.setSignUpDataInStore({
        ...this.state,
        zipCode: this.state.zipCode.toUpperCase(),
      }, this.props.nextPage, DATA_CONFIRM_VIEW);
    }
  };

  checkIfButtonEnabled = ()  => {
    return this.state.firstName.length > 0 && this.state.lastName.length && this.state.email.length >0 && this.state.phone.length >0 && this.state.addressLine1.length > 0 && this.state.city.length > 0 && this.state.zipCode.length > 0
  }



  onAgreeOfDisclaimer = () => {
    this.setState({ serviceEnrollmentModal: false });
  };

  onDisagreeOfDisclaimer = () => {
    this.setState({ serviceEnrollmentModal: false },()=>{
      this.props.history.goBack();
      // console.log(this.props.match.url+"/#1")
      this.props.history.push(this.props.match.url)
      window.location.reload();
    });
  };

  onDisclaimerClose = () => {
    this.setState({ serviceEnrollmentModal: false });
  };

  render() {
    return (
      <div style={{paddingBottom:"-6%"}}>

        <DisclaimerView
          title="You didn’t agree to the service enrollment consent."
          body="This service is provided by My Journey
           and we are unable to proceed and process your data without your consent."
          primaryButtonText="Return to form"
          secondaryButtonText="Exit app sign up"
          open={this.state.serviceEnrollmentModal}
          onClose={this.onDisclaimerClose}
          onAgree={this.onAgreeOfDisclaimer}
          onDisagree={this.onDisagreeOfDisclaimer}
        />

        <form type="post">
          <div>
            <Stack sx={{zIndex:"0"}} spacing={"24px"}>

              <InputTextField
                id="firstName"
                label="First Name"
                name="firstName"
                value={this.state.firstName}
                onChange={(name, value) => this.handleChange(name, value)}
                required
                error={"firstName" in this.state.errors}
                errorText={
                  "firstName" in this.state.errors
                    ? this.state.errors.firstName
                    : ""
                }
              />

              <InputTextField
                id="lastName"
                label="Last Name"
                name="lastName"
                value={this.state.lastName}
                onChange={(name, value) => this.handleChange(name, value)}
                required
                error={"lastName" in this.state.errors}
                errorText={
                  "lastName" in this.state.errors
                    ? this.state.errors.lastName
                    : ""
                }
              />

              <InputTextField
                id="email"
                label="Email ID"
                name="email"
                value={this.state.email}
                onChange={(name, value) => this.handleChange(name, value)}
                required
                error={"email" in this.state.errors}
                errorText={
                  "email" in this.state.errors ? this.state.errors.email : ""
                }
              />

              <InputTextField
                id="phone"
                label="Mobile Number"
                name="phone"
                type="phone"
                value={this.state.phone}
                onChange={(name, value) => this.handleChange(name, value)}
                required
                error={"phone" in this.state.errors}
                errorText={
                  "phone" in this.state.errors ? this.state.errors.phone : ""
                }
              />

              <InputTextField
                id="addressLine1"
                value={this.state.addressLine1}
                label="Address Line 1"
                name="addressLine1"
                onChange={(name, value) => this.handleChange(name, value)}
                required
                error={"addressLine1" in this.state.errors}
                errorText={
                  "addressLine1" in this.state.errors
                    ? this.state.errors.addressLine1
                    : ""
                }
              />

              <InputTextField
                value={this.state.addressLine2}
                id="addressLine2"
                label="Address Line 2"
                name="addressLine2"
                onChange={(name, value) => this.handleChange(name, value)}
              />

              <InputTextField
                value={this.state.city}
                id="city"
                label="City"
                name="city"
                onChange={(name, value) => this.handleChange(name, value)}
                required
                error={"city" in this.state.errors}
                errorText={
                  "city" in this.state.errors ? this.state.errors.city : ""
                }
              />

              <InputTextField
                value={this.state.zipCode}
                id="zipCode"
                label="Postcode"
                name="zipCode"
                onChange={(name, value) => this.handleChange(name, value)}
                required
                maxLength={8}
                error={"zipCode" in this.state.errors}
                errorText={
                  "zipCode" in this.state.errors
                    ? this.state.errors.zipCode
                    : ""
                }
              />

              <DatePicker
                name="stomaMeasuringAppt"
                value={this.state.stomaMeasuringAppt}
                label="Stoma Measuring Appointment"
                error={"stomaMeasuringAppt" in this.state.errors}
                errorText={
                  "stomaMeasuringAppt" in this.state.errors
                    ? this.state.errors.stomaMeasuringAppt
                    : ""
                }
                onChange={(name, value) => this.handleChange(name, value)}
              />

              <DatePicker
                name="surgeryDate"
                value={this.state.surgeryDate}
                label="Surgery Date"
                error={"surgeryDate" in this.state.errors}
                errorText={
                  "surgeryDate" in this.state.errors
                    ? this.state.errors.surgeryDate
                    : ""
                }
                onChange={(name, value) => this.handleChange(name, value)}
              />

              <InputTextField
                id="procedure"
                label="Procedure"
                name="procedure"
                value={this.state.procedure}
                disabled
              />

              <InputTextField
                id="facilityName"
                label="Facility Name"
                name="facilityName"
                value={this.state.facilityName}
                disabled
              />




              <div

                style={{ textAlign: "left", fontSize: "16px", fontWeight: "400" }}
              >
                <FormControlLabel
                  label={
                    <>Service Enrollment <sup className="mandatory-asterisk">*</sup>:</>
                  }
                  control={
                    <Checkbox
                      style={{color: colors.colorPrimaryDark}}
                      size="medium"
                      checked={this.state.serviceEnrollment}
                      onChange={() => {
                        this.setState({
                          serviceEnrollment: !this.state.serviceEnrollment,
                        });
                      }}
                    />
                  }
                />
                <p
                  className="body marketing-consent-checkbox"
                  style={{ fontSize: "14px", paddingLeft: "28px" }}
                >
                  For the purpose of enrolling in a free consumer service program and receiving the associated membership benefits, I consent to the processing of my personal identification, communication preference, health information, product information, third party information, transactional information, and request details. I understand that these services are free of charge, and there is no obligation to purchase anything to receive them. I understand that I am under no obligation to participate in any/ all of the applicable membership benefits, such as assistance identifying product supplier options, and receiving product samples and other complimentary items. I understand that Hollister may communicate with me about consumer services, or as part of a membership benefit, using the contact information (postal address, email, phone, & sms text) that I have provided. Hollister reserves the right to change the consumer service programs (Magokoro, QualiVida, My Journey<sup className="sup-text">SM</sup>, & Vivre+) at any time.<br/><br/>
                  Hollister may also share my personal and health information with my nurse, doctor, healthcare product supplier, other healthcare professional or other third parties as indicated in the data privacy notice, which is attached to this form and can also be found at&nbsp;
                  <a className="cursor-pointer" href="https://www.hollister.com/en/privacypolicy" target="_blank" ><u className="privacy-notice-url">https://www.hollister.com/en/privacypolicy.</u></a>.
                </p>
              </div>

              <div

                style={{ textAlign: "left", fontSize: "16px",fontWeight: "400" }}
              >
                <FormControlLabel
                  label={
                    "Direct Marketing: "
                  }
                  control={
                    <Checkbox
                      style={{color: colors.colorPrimaryDark,position:"relative"}}
                      size="medium"
                      checked={this.state.marketingConsent}
                      onChange={() => {
                        this.setState({
                          marketingConsent: !this.state.marketingConsent,
                        });
                      }}
                    />
                  }
                />
                <p
                  className="body marketing-consent-checkbox"
                  style={{ fontSize: "14px", paddingLeft: "28px" }}
                >
                  For the purpose of receiving information about products, promotions, and other direct marketing campaigns, I consent to the processing of my personal identification, communication preference, health information, and request details. I understand that Hollister may communicate with me about these topics, using the contact information (postal address, email, phone, & sms text) that I have provided.
                </p>

                <div className="privacy-notice">
                  <div className="privacy-notice-title">Privacy Notice</div>
                  <div
                    className="body marketing-consent-checkbox"
                    style={{ fontSize: "14px", paddingLeft: "28px" }}
                  >
                    <p className="privacy-notice-text">Your consent is optional and you have the right to withdraw it at any time. To withdraw your consent or to make changes to your communication preferences, contact us at <a className="cursor-pointer" href="mailto:unsubscribe@hollister.com" target="_blank" ><u className="privacy-notice-url">unsubscribe@hollister.com.</u></a> Such withdrawal only has future effect (i.e. the withdrawal of your consent has no effect on the lawfulness of the data processing and disclosures before the withdrawal was made).<br/><br/>

                      Please find further details regarding the processing of your personal data and your legal rights in our full
                      <a className="cursor-pointer" href={this.props.privacyNoticeUrl} target="_blank" >
                        <u className="privacy-notice-url">
                          &nbsp;Privacy Notice</u></a>.</p>
                  </div>
                </div>
              </div>



            </Stack>
            <div className="continue-button-for-form">
              <PrimaryButton
                disabled={!this.checkIfButtonEnabled()}
                fullWidth
                text="Continue"
                onClick={this.handleSubmit}
              />
            </div>
          </div>
        </form>

      </div>
    );
  }
}

NameView.propTypes = {
  firstName: PropTypes.string,
  lastName: PropTypes.string,
  email: PropTypes.string,
  phone: PropTypes.string,
  addressLine1: PropTypes.string,
  addressLine2: PropTypes.string,
  city: PropTypes.string,
  zipCode: PropTypes.string,
  stomaMeasuringAppt: PropTypes.object,
  surgeryDate: PropTypes.object,
  procedure: PropTypes.string,
  facilityName: PropTypes.string,
  modifierIdentifier: PropTypes.string,
  facilityCode: PropTypes.string,
  marketingConsent: PropTypes.bool,
  getPrivacyNoticeUrl: PropTypes.func,
};

const mapStateToProps = (state) => ({
  firstName: state.patientRegister.firstName,
  lastName: state.patientRegister.lastName,
  email: state.patientRegister.email,
  phone: state.patientRegister.phone,
  addressLine1: state.patientRegister.addressLine1,
  addressLine2: state.patientRegister.addressLine2,
  city: state.patientRegister.city,
  zipCode: state.patientRegister.zipCode,
  stomaMeasuringAppt:
  state.patientRegister.stomaMeasuringAppt,
  surgeryDate: state.patientRegister.surgeryDate,
  procedure: state.patientRegister.procedure,
  modifierIdentifier: state.patientRegister.modifierIdentifier,
  facilityName: state.patientRegister.facilityName,
  facilityCode: state.patientRegister.facilityCode,
  marketingConsent:
  state.patientRegister.marketingConsent,
  serviceEnrollment:
  state.patientRegister.serviceEnrollment,
  privacyNoticeUrl: state.privacyNotice.privacyNoticeUrl,
});

const mapDispatchToProps = (dispatch) => ({
  setSignUpDataInStore: (data, nextPage, page) =>
    dispatch(setSignUpData(data, nextPage, page)),
  setUserConsent: () =>
    dispatch(setUserConsentInRedux()),
  getPrivacyNoticeUrl: () =>
    dispatch(getPrivacyNoticeUrl()),
});

const withConnect = connect(mapStateToProps, mapDispatchToProps);

export default compose(withRouter, withConnect)(NameView);

