import React from 'react';
import InputMask from 'react-input-mask';
import {PHONE_NUMBER_MASK} from "../../constants";

class PhoneInput extends React.PureComponent {// eslint-disable-line react/prefer-stateless-function
  render() {
    return <InputMask {...this.props} mask={PHONE_NUMBER_MASK} maskChar={null} type="tel" />;
  }
}

export default PhoneInput;
