import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { compose } from 'redux';
import { SingleSelectWithoutBorder as CustomizedSelect } from 'components/SelectV2';
import { getSearchCategoryListRequest } from 'common/actions';
import injectReducer from 'utils/injectReducer';
import { getSearchCategoryListReducer } from 'common/reducer';
import './style.scss';

class SelectCategory extends React.Component {
  constructor(props) {
    super(props);
  }

  componentDidMount() {
    this.props.getSearchCategoryListRequest();
  }

  render() {
    const { props } = this;
    return (Object.keys(this.props.searchCategoryList).length > 0 && <CustomizedSelect
        id="select-category"
        className="form-group"
        onChange={this.props.selectCategory}
        defaultValue= {{value: this.props.category, label: this.props.searchCategoryList[this.props.category]}}
        options={
          Object.keys(this.props.searchCategoryList).map((key) => ({
            value: key, label: this.props.searchCategoryList[key],
          }))
        }
      />
    );
  }
}

SelectCategory.propTypes = {
  selectCategory: PropTypes.func,
  category: PropTypes.string,
  searchCategoryList: PropTypes.object,
  getSearchCategoryListRequest: PropTypes.func,
};

const mapDispatchToProps = (dispatch) => ({
  getSearchCategoryListRequest: () => dispatch(getSearchCategoryListRequest()),
});

const mapStateToProps = (state) => ({
  searchCategoryList: state.selectCategory.categoryList,
});

const withConnect = connect(mapStateToProps, mapDispatchToProps);

const withReducer = injectReducer({
  key: 'selectCategory',
  reducer: getSearchCategoryListReducer,
})

export default compose(
  withReducer,
  withConnect,
)(SelectCategory);
