/**
 * Create the store with dynamic reducers
 */

import { createStore, applyMiddleware, compose } from 'redux';
import thunk from 'redux-thunk';


import createReducer from './reducers';

export default function configureStore(initialState = {}, history) {
  const middlewares = [
    // routerMiddleware(history),
    thunk,
  ];

  const enhancers = [
    applyMiddleware(...middlewares),
  ];

  // If Redux DevTools Extension is installed use it, otherwise use Redux compose
  /* eslint-disable no-underscore-dangle */
  const composeEnhancers = process.env.NODE_ENV !== 'production'
      && typeof window === 'object'
      && window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__
    ? window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__({})
    : compose;

  // const store = createStore(
  //   createReducer(),
  //   initialState,
  //   composeEnhancers(...enhancers)
  // );
  const store = createStore(
    createReducer(),
    initialState,
    compose(
      applyMiddleware(
        // routerMiddleware(history),
        thunk,
      )
    )
  );

  // Extensions
  store.injectedReducers = {}; // Reducer registry

  // Make reducers hot reloadable, see http://mxs.is/googmo
  /* istanbul ignore next */
  if (module.hot) {
    module.hot.accept('./reducers', () => {
      store.replaceReducer(createReducer);
    });
  }

  return store;
}
