export const BackButton = (props) => {
  return (
    <div
      className={props.className}
      role="button"
      style={props.style}
      onClick={() => {
        props.goBack();
      }}
    >
      <span className="btn-text cursor-pointer">Go Back</span>
    </div>
  );
};
