import React from 'react';
import Select from 'react-select';
import customStyles from './DefaultStyle';

const SingleSelect = (props) => {
  const { className: classes } = props;

  return (
    <Select
      {...props}
      styles={customStyles}
      classNamePrefix="single-select"
      className={`single-select-container ${classes || ''}`}
    />
  );
};

export default SingleSelect;
