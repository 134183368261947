export const GET_PASSIVE_MODE_METADATA_REQUEST_SENT = 'src/patientApp/PassiveMode/GET_PASSIVE_MODE_METADATA_REQUEST_SENT';
export const GET_PASSIVE_MODE_METADATA_REQUEST_SUCCEED = 'src/patientApp/PassiveMode/GET_PASSIVE_MODE_METADATA_REQUEST_SUCCEED';
export const GET_PASSIVE_MODE_METADATA_REQUEST_FAILED = 'src/patientApp/PassiveMode/GET_PASSIVE_MODE_METADATA_REQUEST_FAILED';

export const GET_PASSIVE_MODE_CONTENT_REQUEST_SENT = 'src/patientApp/PassiveMode/GET_PASSIVE_MODE_CONTENT_REQUEST_SENT';
export const GET_PASSIVE_MODE_CONTENT_REQUEST_SUCCEED = 'src/patientApp/PassiveMode/GET_PASSIVE_MODE_CONTENT_REQUEST_SUCCEED';
export const GET_PASSIVE_MODE_CONTENT_REQUEST_FAILED = 'src/patientApp/PassiveMode/GET_PASSIVE_MODE_CONTENT_REQUEST_FAILED';

export const SUBMIT_TASK_RESPONSE_REQUEST_SENT = 'src/patientApp/PassiveMode/SUBMIT_TASK_RESPONSE_REQUEST_SENT';
export const SUBMIT_TASK_RESPONSE_REQUEST_SUCCEED = 'src/patientApp/PassiveMode/SUBMIT_TASK_RESPONSE_REQUEST_SUCCEED';
export const SUBMIT_TASK_RESPONSE_REQUEST_FAILED = 'src/patientApp/PassiveMode/SUBMIT_TASK_RESPONSE_REQUEST_FAILED';

export const UPDATE_APPOINTMENT_REQUEST_SENT = 'src/Appointments/UPDATE_APPOINTMENT_REQUEST_SENT';
export const UPDATE_APPOINTMENT_REQUEST_SUCCEED = 'src/Appointments/UPDATE_APPOINTMENT_REQUEST_SUCCEED';
export const UPDATE_APPOINTMENT_REQUEST_FAILED = 'src/Appointments/UPDATE_APPOINTMENT_REQUEST_FAILED';
