import camelcaseKeys from 'change-case-object';
import {
  getConversationMessages as MessagesApiCall,
  postSendMessage as SendMessageApiCall,
} from 'services/patientDetails';
import {
  GET_CONVERSATION_MESSAGES_REQUEST_FAILED, GET_CONVERSATION_MESSAGES_REQUEST_SENT, GET_CONVERSATION_MESSAGES_REQUEST_SUCCEED,
  SEND_MESSAGE_REQUEST_SENT, SEND_MESSAGE_REQUEST_SUCCEED, SEND_MESSAGE_REQUEST_FAILED,
} from './constants';

export const getMessagesRequestSent = () => ({
  type: GET_CONVERSATION_MESSAGES_REQUEST_SENT,
});

export const getMessagesRequestSucceed = (conversation, messages) => ({
  type: GET_CONVERSATION_MESSAGES_REQUEST_SUCCEED,
  conversation,
  messages,
});

export const getMessagesRequestFailed = () => ({
  type: GET_CONVERSATION_MESSAGES_REQUEST_FAILED,
});

export const getConversationMessages = (conversationId) => (dispatch) => {
  dispatch(getMessagesRequestSent());
  MessagesApiCall(conversationId)
    .then((response) => {
      const { conversation, messages } = response.data;
      const messagesData = camelcaseKeys.camelCase(messages);
      const conversationData = camelcaseKeys.camelCase(conversation);
      dispatch(getMessagesRequestSucceed(conversationData, messagesData));
    })
    .catch(() => {
      dispatch(getMessagesRequestFailed());
    });
};

export const sendMessagesRequestSent = () => ({
  type: SEND_MESSAGE_REQUEST_SENT,
});

export const sendMessagesRequestSucceed = (message) => ({
  type: SEND_MESSAGE_REQUEST_SUCCEED,
  message,
});

export const sendMessagesRequestFailed = () => ({
  type: SEND_MESSAGE_REQUEST_FAILED,
});

export const sendMessage = (conversationId, message, attachments) => (dispatch) => {
  dispatch(sendMessagesRequestSent());
  SendMessageApiCall(conversationId, message, attachments)
    .then((response) => {
      const data = camelcaseKeys.camelCase(response.data);
      dispatch(sendMessagesRequestSucceed((data)));
    })
    .catch(() => {
      dispatch(sendMessagesRequestFailed());
    });
};
