import React, { Component, useState, useEffect } from "react";
import PrimaryButton from "../../../components/StyleGuideComponents/PrimaryButton";
import SecondaryButton from "../../../components/StyleGuideComponents/SecondaryButton";
import "../patientRegister.scss";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import useMediaQuery from "@mui/material/useMediaQuery";
import { useTheme, ThemeProvider } from "@mui/material/styles";
import Typography from "@mui/material/Typography";
import { modalTheme } from "../../../components/StyleGuideComponents/common/Themes";
import IconButton from "@mui/material/IconButton";
import CloseIcon from "@mui/icons-material/Close";
import PropTypes from "prop-types";
import Stack from "@mui/material/Stack";
import { SpaOutlined } from "@mui/icons-material";
import CancelIcon from '@mui/icons-material/Cancel';

function DisclaimerView(props) {
  let theme = useTheme();
  let fullScreen = useMediaQuery(theme.breakpoints.down("md"));

  DisclaimerView.propTypes = {
    onAgree: PropTypes.func.isRequired,
    onDisagree: PropTypes.func.isRequired,
    open: PropTypes.bool.isRequired,
    onClose: PropTypes.func.isRequired,
  };

  return (
    <div className="dialog-box-wrapper no-margin">
      <ThemeProvider theme={modalTheme}>
        <Dialog
          fullScreen={fullScreen}
          open={props.open}
          onClose={props.onClose}
          aria-labelledby="responsive-dialog-title"
        >
          <DialogTitle
            variant="h2"
            id="responsive-dialog-title"
            className="dialog-box-title"
          >
            {
              props.isClosable?(
                <div className="dialog-header">
                <span></span>
                <span className="modal-header-text">{props.title}</span>
                <span className="modal-close-icon-wrapper">

                  <IconButton aria-label="close" onClick={props.onClose}>
                    <span className="modal-close-icon-background"><CloseIcon className="modal-close-icon"/></span>
                  </IconButton>
                </span>
              </div>
              ):(
                <div className="modal-header-text">{props.title}

                </div>
              )

}
          </DialogTitle>
          <DialogContent className="dialog-content-wrapper">
            <DialogContentText className="dialog-box-text">
              {props.body}
            </DialogContentText>
          </DialogContent>

          <DialogActions className="dialog-actions-wrapper">
            {props.secondaryButtonText && (<SecondaryButton
              onClick={props.onDisagree}
              text={props.secondaryButtonText}
            />)
}
            <PrimaryButton
              onClick={props.onAgree}
              text={props.primaryButtonText}
            />
          </DialogActions>
        </Dialog>
      </ThemeProvider>
    </div>
  );
}

export default DisclaimerView;
