import React from 'react';
import PropTypes from 'prop-types';
import './empty_view.scss';


class Emptyview extends React.PureComponent {
  render() {
    return (
      <div className="row no-margin">
        <div className="empty-container">
          <div className="col-xs-12 img">
            {
              this.props.imgSrc && this.props.imgSrc !== '' ? <img src={this.props.imgSrc} alt="" /> : <span className={`icon ${this.props.className}`}></span>
            }
          </div>
          <div className="col-xs-12 text">{this.props.message}</div>
        </div>
      </div>
    );
  }
}

Emptyview.propTypes = {
  imgSrc: PropTypes.any,
  className: PropTypes.any,
  message: PropTypes.string,
};

export default Emptyview;
