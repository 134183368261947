import axios from "./base";
import {
  GET_ALL_LOCATIONS_OF_THE_DEPARTMENT,
  ADD_NEW_LOCATION,
  UPDATE_LOCATION,
  GET_TIMEZONES
} from "./constants";

export const fetchAllLocationsOfTheDepartment = (params) =>
         axios.get(GET_ALL_LOCATIONS_OF_THE_DEPARTMENT, { params });

export const addNewLocation = (params) => axios.post(ADD_NEW_LOCATION, params);

export const updateLocation = (params) => {
  const url = UPDATE_LOCATION.replace(":id", params.id);
  return axios.put(url, params);
};

export const fetchTimezones = () =>
  axios.get(GET_TIMEZONES);
