import React from "react";
import "../patientRegister.scss";
import PropTypes from "prop-types";
import Stack from "@mui/material/Stack";
import {connect} from "react-redux";
import {compose} from "redux";
import InputTextField from "../../../components/StyleGuideComponents/InputTextField";
import PrimaryButton from "../../../components/StyleGuideComponents/PrimaryButton";
import {generateOtp, setOtpInState, setSignUpDataForExistingUser, verifyOtp} from "../action";
import validate from "common/validator";
import {withRouter} from 'react-router';
import Container from "@mui/material/Container";
import Box from "@mui/material/Box";
import {getItemFromStorage} from "services/storage";
import isEmpty from "lodash/isEmpty";
import DatePicker from "./../../../components/StyleGuideComponents/DatePicker";


import {ADD_PROCEDURE_VERIFICATION_VIEW} from "../constants";

import {signInUser,} from "common/authThunkActions";
import {DATE_FORMAT} from "../../../constants";

class LoggedInUserProcedureView extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      flowId: props.flowId || null,
      flowType: props.flowType || "sign_up",
      firstName: props.firstName || "",
      emailOtp: props.emailOtp || "",
      email: props.email || "",
      errors: [],
      phone: props.phone || "",
      zipCode: props.zipCode || "",
      emailOrMobileNumber: "",
      password: "",
      isSignedIn: props.isSignedIn,

      stomaMeasuringAppt: props.stomaMeasuringAppt || null,
      surgeryDate: props.surgeryDate || null,
      modifierIdentifier: props.modifierIdentifier || props.match.params.procedure,
      procedure: props.procedure || "",
      facilityCode: props.facilityCode || props.match.params.facility_name,
      facilityName: props.facilityName || "",

    };
  }

  phoneRule = [
    { rule: "isPhone", message: "Mobile Number or Email is invalid" },
    { rule: "isRequired", message: "Mobile Number or Email is required" },
  ];

  emailRule = [
    { rule: "isEmail", message: "Mobile Number or Email is invalid" },
    { rule: "isRequired", message: "Mobile Number or Email is required" },
  ];

  validationConfig = {
    fields: ["stomaMeasuringAppt", "surgeryDate"],
    rules: {
      stomaMeasuringAppt: [
        { rule: "isValidDate", message: `Appointment Date is Invalid (Format: ${DATE_FORMAT.toUpperCase()})` },
      ],
      surgeryDate: [
        { rule: "isValidDate", message: `Surgery Date is Invalid (Format: ${DATE_FORMAT.toUpperCase()})` },
      ],
    },
  };

  handleChange = (name, value) => {
    let errors = this.state.errors
    delete errors[name]

    this.setState({ [name]: value,errors:errors });
  };

  onFormValidationSuccess = () => {
    this.props.setSignUpDataForExistingUser(this.state,this.props.nextPage);
    this.props.nextPage(ADD_PROCEDURE_VERIFICATION_VIEW)
  };

  onFormValidationFailure = errors => {
    this.setState({ errors });
  }

  checkIfButtonEnabled = ()  => {
    return this.state.firstName.length > 0 && this.state.lastName.length && this.state.email.length >0 && this.state.phone.length >0 && this.state.addressLine1.length > 0 && this.state.city.length > 0 && this.state.zipCode.length > 0
  }

  submitForm = (e) => {
    validate(
      this.validationConfig,
      this.state,
      this.onFormValidationFailure,
      this.onFormValidationSuccess
    );
  };

  componentWillReceiveProps(nextProps) {
    if(nextProps.isSignedIn != this.state.isSignedIn)
    {
      this.setState({ isSignedIn: nextProps.isSignedIn });
    }
    const formData = {};
    Object.keys(nextProps).forEach((key) => {
      if (key in this.state) {

        if (nextProps[key] != this.state[key]) {
          formData[key] = nextProps[key];
        }
      }
    });
    if (Object.keys(formData).length) {
      this.setState({ ...formData });
    }
  }

  // handleChange = (name, value) => {
  //   this.setState({ [name]: value });
  // };

  onValidationFailure = (errors) => {
    this.setState({ errors: errors });
  };

  onNextValidationSuccess = () => {
    const params = {
      verification_code: this.state.emailOtp,
      flow_type: this.state.flowType,
      flow_id: this.state.flowId,
      email: this.state.email,
    };
    this.props.setOtpInReduxState({emailOtp: this.state.emailOtp})
    this.props.verifyEmailOtp(params, this.props.nextPage);
    // this.props.nextPage();
  };

  componentDidUpdate = () => {
    if(this.state.isSignedIn && this.state.page == 9){
      this.props.nextPage();
    }
  }

  render() {
    if(!this.state.isSignedIn){
      this.props.prevPage();
    }
    return (
      <div className="procedure-screen-wrapper no-padding">
        <Container maxWidth="lg">
          <Box className="procedure-page-container">
            <div className="login-form">
              <form type="post">
                <div>
                  <Stack sx={{zIndex:"0"}} spacing={"24px"}>
                    <DatePicker
                      name="stomaMeasuringAppt"
                      value={this.state.stomaMeasuringAppt}
                      label="Stoma Measuring Appointment"
                      error={"stomaMeasuringAppt" in this.state.errors}
                      errorText={
                        "stomaMeasuringAppt" in this.state.errors
                          ? this.state.errors.stomaMeasuringAppt
                          : ""
                      }
                      onChange={(name, value) => this.handleChange(name, value)}
                    />
                    <DatePicker
                      name="surgeryDate"
                      value={this.state.surgeryDate}
                      label="Surgery Date"
                      error={"surgeryDate" in this.state.errors}
                      errorText={
                        "surgeryDate" in this.state.errors
                          ? this.state.errors.surgeryDate
                          : ""
                      }
                      onChange={(name, value) => this.handleChange(name, value)}
                    />
                    <InputTextField
                      id="procedure"
                      label="Procedure"
                      name="procedure"
                      value={this.state.procedure}
                      disabled
                    />
                    <InputTextField
                      id="facilityName"
                      label="Facility Name"
                      name="facilityName"
                      value={this.state.facilityName}
                      disabled
                    />
                  </Stack>
                </div>
              </form>

            </div>
            <div className="procedure-input-continue-button">
              <PrimaryButton onClick={this.submitForm} text="Continue"  />
            </div>
          </Box>
        </Container>
      </div>

    );
  }
}

LoggedInUserProcedureView.propTypes = {
  isLoading: PropTypes.bool,
  firstName: PropTypes.string,
  email: PropTypes.string,
  stomaMeasuringAppt: PropTypes.object,
  surgeryDate: PropTypes.object,
  procedure: PropTypes.string,
  facilityName: PropTypes.string,
  modifierIdentifier: PropTypes.string,
  facilityCode: PropTypes.string,
  isSignedIn: PropTypes.bool.isRequired,
};

const mapStateToProps = (state) => ({
  firstName: state.patientRegister.firstName,
  email: state.patientRegister.email,
  phone: state.patientRegister.phone,
  zipCode: state.patientRegister.zipCode,
  stomaMeasuringAppt:
  state.patientRegister.stomaMeasuringAppt,
  surgeryDate: state.patientRegister.surgeryDate,
  procedure: state.patientRegister.procedure,
  modifierIdentifier: state.patientRegister.modifierIdentifier,
  facilityName: state.patientRegister.facilityName,
  facilityCode: state.patientRegister.facilityCode,
  flowType: state.patientRegister.flowType,
  flowId:
  state.patientRegister.flowId,
  isSignedIn:
    state.currentUser.isSignedIn || !isEmpty(getItemFromStorage("token")),
});

const mapDispatchToProps = (dispatch) => ({
  signInUser: (params) => dispatch(signInUser(params)),
  generateEmailOtp: (params, nextPage, redirect = true) =>
    dispatch(generateOtp(params, nextPage, redirect)),
  setOtpInReduxState: (params) =>
    dispatch(setOtpInState(params)),
  generatePhoneOtp: (params, nextPage) =>
    dispatch(generateOtp(params, nextPage)),
  verifyEmailOtp: (params, nextPage) => dispatch(verifyOtp(params, nextPage)),
  setSignUpDataForExistingUser: (data,nextPage) =>
    dispatch(setSignUpDataForExistingUser(data,nextPage)),
});
const withConnect = connect(mapStateToProps, mapDispatchToProps);

export default compose(withRouter, withConnect)(LoggedInUserProcedureView);
