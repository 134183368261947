import React, { useState, useEffect } from "react";
import PropTypes from 'prop-types';
import { connect } from "react-redux";
import { compose, combineReducers } from "redux";
import injectReducer from "utils/injectReducer";
import Cookies from 'universal-cookie';
import {
  getCookieConsentText, 
  postCookieConsent,
} from './actions';
import { getCookieConsentReducer } from "./reducer";
import "./cookieConsent.scss";

const UserCookieConsent = (props) => {
  const cookies = new Cookies();
  const [isConsentProvided, setIsConsentProvided] = useState(cookies.get('cookieConsentProvided') || false);

  useEffect(()=>{
    props.getCookieConsent()
  },[])

  const onAgreeClick = () => {
    if (!isConsentProvided) {
      setIsConsentProvided(true);
      cookies.set('cookieConsentProvided', true);
      props.postCookieConsent(
        !isConsentProvided
      );
    }
  }

  const RenderConsentText = () => (
    <div className="consent-text" dangerouslySetInnerHTML={{ __html: props.cookieConsentText }}/>
  )

  const RenderUserConsentPopUp = () => {
    return(
      <div className="consent-footer">
        <div className="consent-text-wrapper">
          <span className="pop-up-header">This webite uses cookies</span>
          <br/>
          <RenderConsentText/>
        </div>
        <div className="consent-buttons-wrapper">
          <span role="button" className="agree" onClick={onAgreeClick}>Accept</span>
        </div>
      </div>
    );
  }

  return (
    <React.Fragment>
      {
        isConsentProvided ?
          null
        :<RenderUserConsentPopUp/>
      }
    </React.Fragment>
  );
}

UserCookieConsent.propTypes = {
  consentProvided: PropTypes.bool,
  postCookieConsent: PropTypes.func
};

const mapStateToProps = (state) => ({
  cookieConsentText: state.cookieConsent.getCookieConsentReducer.cookieConsentText,
});

const mapDispatchToProps = (dispatch) => ({
  getCookieConsent: () => dispatch(getCookieConsentText()),
  postCookieConsent: (params) => dispatch(postCookieConsent(params)),
});

const withConnect = connect(mapStateToProps, mapDispatchToProps);

const withReducer = injectReducer({
  key: "cookieConsent",
  reducer: combineReducers({
    getCookieConsentReducer,
  }),
});

export default compose(withReducer, withConnect)(UserCookieConsent);
