import {useEffect, useState} from "react";
import {useHistory} from "react-router-dom";
import "../patientRegister.scss";
import DisclaimerView from "./DisclaimerView";

import Stack from "@mui/material/Stack";
import PropTypes from "prop-types";
import Media from "react-media";
import {connect} from "react-redux";
import PrimaryButton from "../../../components/StyleGuideComponents/PrimaryButton";
import SecondaryButton from "../../../components/StyleGuideComponents/SecondaryButton";
import {generateOtp, pageChange, saveProcedure, setProcedureConfirmationFlag,} from "../action";

import {trimCountryCodeFromPhoneNumber} from "../../../utils/stringUtils";

import {PHONE_VERIFY_VIEW, SUCCESS_VIEW, WELCOME_VIEW} from "../constants";
import Strings from "../../../strings";

const AddProcedureVerificationView = (props) => {
  const [procedure, setProcedure] = useState(props.procedure || "");
  const [flowType, setFlowType] = useState(props.flowType || "");
  const [flowId, setFlowId] = useState(props.flowId || "");
  const [phone, setPhone] = useState(props.phone || "");
  const [showMessageModal, setShowMessageModal] = useState(false);

  useEffect(() => {
    setProcedure(props.procedure);
  }, [props.procedure]);

  window.scroll({
    top: 0,
    left: 0,
    behavior: "smooth",
  });

  useEffect(() => {
    setFlowType(props.flowType);
  }, [props.flowType]);

  useEffect(() => {
    setFlowId(props.flowId);
  }, [props.flowId]);

  useEffect(() => {
    setPhone(props.phone);
  }, [props.phone]);

  const history = useHistory();
  useEffect(() => {
    setProcedure(props.procedure);
  }, [props.procedure]);

  const handleSubmit = (procedureStatus) => {
    const params = {
      location_code: props.facilityCode,
      procedure_modifier: props.modifierIdentifier,
      procedure_date: props.surgeryDate,
      stoma_marking_date: props.stomaMeasuringAppointment,
      flow_type: props.flowType,
      flow_id: props.flowId,
    };
    props.saveUserProcedure(params, props.nextPage, SUCCESS_VIEW);
  };

  const handleIncorrectProcedureSubmit = () => {
    props.handlePageChange(WELCOME_VIEW);
  };

  const handleIncorrectProcedure = () => {
    setShowMessageModal(true);
  };

  const startsWithVowel = (word) => {
    var vowels = "aeiouAEIOU";
    return vowels.indexOf(word[0]) !== -1;
  };

  const getModalBodyText = () => {
    return (
      <span>
        If it’s incorrect please contact your Facility for the correct QR Code
      </span>
    );
  };

  const handleOTP = () => {
    const params = {
      flow_type: flowType,
      flow_id: flowId,
      contact_number: trimCountryCodeFromPhoneNumber(phone),
    };
    props.generatePhoneOtp(params, props.nextPage, PHONE_VERIFY_VIEW);
  };

  return (
    <div className="text-center">
      <Media query={{ maxWidth: 480 }}>
        {(screenIsSmall) =>
          screenIsSmall ? <></> : <hr className="hr-line" />
        }
      </Media>

          <div className="procedure-verification-content">
            <div>Please verify that your procedure is {startsWithVowel(procedure?procedure:"")?"an":"a"}
             <span className="dark-highlight"> {procedure?procedure.toUpperCase():""} </span>  surgery.<br/><br/>
             If it’s incorrect, then you can contact your My Journey advisor on&nbsp;
             <Media query={{ maxWidth: 480 }}>
                {
                  (screenIsSmall) => screenIsSmall
                    ? (
                      <span className="highlight-text contact-number">
                        <a href={`tel:${Strings.PROCEDURE_VERIFICATION_PHONE_NUMBER}`}>
                          {Strings.PROCEDURE_VERIFICATION_PHONE_NUMBER}
                        </a>
                      </span>
                    ) : (
                      <span className="highlight-text contact-number">{Strings.PROCEDURE_VERIFICATION_PHONE_NUMBER}</span>

                    )
                }
              </Media>
            </div>
          </div>

      <Stack spacing={"17px"} className="procedure-verification-button">
        <PrimaryButton
          onClick={() => handleSubmit(true)}
          submit
          text="Yes, it's correct"
        />

        <SecondaryButton
          onClick={() => handleIncorrectProcedure()}
          cancel
          text="No, it's incorrect"
        />
      </Stack>

      <DisclaimerView
        title="We’ve got you!"
        body={getModalBodyText()}
        primaryButtonText="Log Out"
        open={showMessageModal}
        onAgree={() => handleIncorrectProcedureSubmit()}
      />
    </div>
  );
};

AddProcedureVerificationView.propTypes = {
  flowId: PropTypes.string,
  flowType: PropTypes.string,
  isLoading: PropTypes.bool,
  firstName: PropTypes.string,
  lastName: PropTypes.string,
  email: PropTypes.string,
  phone: PropTypes.string,
  addressLine1: PropTypes.string,
  addressLine2: PropTypes.string,
  city: PropTypes.string,
  state: PropTypes.string,
  zipCode: PropTypes.string,
  stomaMeasuringAppt: PropTypes.object,
  surgeryDate: PropTypes.object,
  procedure: PropTypes.string,
  facilityName: PropTypes.string,
  marketingConsent: PropTypes.string,
  handlePageChange: PropTypes.func,
};

const mapStateToProps = (state) => ({
  procedure: state.patientRegister.procedure,
  phone: state.patientRegister.phone,
  flowType: state.patientRegister.flowType,
  flowId: state.patientRegister.flowId,
  facilityCode: state.patientRegister.facilityCode,
  modifierIdentifier:
    state.patientRegister.modifierIdentifier,
  surgeryDate: state.patientRegister.surgeryDate,
  stomaMeasuringAppointment:
    state.patientRegister.stomaMeasuringAppt,
});

const mapDispatchToProps = (dispatch) => ({
  generatePhoneOtp: (params, nextPage, page) =>
    dispatch(generateOtp(params, nextPage, page)),
  saveUserProcedure: (params, nextPage, page) =>
    dispatch(saveProcedure(params, nextPage, page)),
  setProcedureCorrectFlag: (params) =>
    dispatch(setProcedureConfirmationFlag(params)),
  handlePageChange: (page, redirect = true) =>
    dispatch(pageChange(page, redirect)),
});
const withConnect = connect(mapStateToProps, mapDispatchToProps);

export default withConnect(AddProcedureVerificationView);
