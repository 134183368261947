import {
  GET_OSTOMY_INFO_REQUEST_SENT,
  GET_OSTOMY_INFO_REQUEST_SUCCEED,
  GET_OSTOMY_INFO_REQUEST_FAILED,
  GET_CARE_TEAM_INFO_REQUEST_SENT,
  GET_CARE_TEAM_INFO_REQUEST_SUCCEED,
  GET_CARE_TEAM_INFO_REQUEST_FAILED,
  GET_PERSONAL_INFO_REQUEST_SENT,
  GET_PERSONAL_INFO_REQUEST_SUCCEED,
  GET_PERSONAL_INFO_REQUEST_FAILED,
} from "./constants";

const profileTabInitialState = {
  isLoading: false,
  stomaInfoData: {},
  careTeamInfoData: {},
  personalInfoData: {},
};

const profileTabReducer = (state = profileTabInitialState, action) => {
  switch (action.type) {
    case GET_OSTOMY_INFO_REQUEST_SENT:
      return { ...state, isLoading: true };
    case GET_CARE_TEAM_INFO_REQUEST_SENT:
      return { ...state, isLoading: true };
    case GET_PERSONAL_INFO_REQUEST_SENT:
      return { ...state, isLoading: true };

    case GET_OSTOMY_INFO_REQUEST_SUCCEED:
      return {
        ...state,
        isLoading: false,
        stomaInfoData: action.payload,
      };
    case GET_CARE_TEAM_INFO_REQUEST_SUCCEED:
      return {
        ...state,
        isLoading: false,
        careTeamInfoData: action.payload,
      };
    case GET_PERSONAL_INFO_REQUEST_SUCCEED:
      return {
        ...state,
        isLoading: false,
        personalInfoData: action.payload,
      };

    case GET_OSTOMY_INFO_REQUEST_FAILED:
      return { ...state, isLoading: false };

    case GET_CARE_TEAM_INFO_REQUEST_FAILED:
      return { ...state, isLoading: false };

    case GET_PERSONAL_INFO_REQUEST_FAILED:
      return { ...state, isLoading: false };
    default:
      return state;
  }
};

export default profileTabReducer;
