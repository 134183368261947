import React, {useEffect} from "react";
import success1x from "assets/images/success@1x.png";
import success2x from "assets/images/success@2x.png";
import success3x from "assets/images/success@3x.png";
import googlePlayIcon from "assets/images/googelplayicon.png";
import appStoreIcon from "assets/images/applestoreicon.png";
import "../patientRegister.scss";
import Media from "react-media";
import PropTypes from "prop-types";
import {useDispatch, useSelector} from "react-redux";
import {signOutUser} from "common/authThunkActions";
import {APP_STORE_URL, GOOGLE_PLAY_STORE_URL} from "services/constants";
import Strings from "../../../strings";

const SuccessView = (props) => {
  const dispatch = useDispatch();
  const { isSignedIn } = useSelector(store => store.currentUser);

  useEffect(() => {
    if (isSignedIn) {
      dispatch(signOutUser());
    }
  }, []);

  return (
    <div className="signup-background">
      <div className="image-container">
        <Media query={{ maxWidth: 991 }}>
          {(screenIsSmall) =>
            screenIsSmall ? (
              <Media query={{ maxWidth: 480 }}>
                {(screenIsSmall) =>
                  screenIsSmall ? (
                    <img
                      src={success1x}
                      className="hero-image"
                      alt="welcome-image"
                    ></img>
                  ) : (
                    <img
                      src={success2x}
                      className="hero-image"
                      alt="welcome-image"
                    ></img>
                  )
                }
              </Media>
            ) : (
              <img
                src={success3x}
                className="hero-image"
                alt="welcome-image"
              ></img>
            )
          }
        </Media>
      </div>
      <div className="text-container-wrapper">
        <div className="welcome-info-container">
          <div className="welcome-info">
            <span className="success-secure-start-title-text">All Set!</span>
            <span className="successSubTitleText">
              Download and Login to My Journey to finish signing up
            </span>
          </div>
          <div className="app-url-btn-container">
            <a href={GOOGLE_PLAY_STORE_URL} target="_blank">
              <img
                src={googlePlayIcon}
                className="store-image"
                alt="google-play-store"
              />
            </a>
            <a href={APP_STORE_URL} target="_blank">
              <img
                src={appStoreIcon}
                className="store-image"
                alt="apple-play-store"
              />
            </a>
          </div>
          <p className="success-page-secure-start-help-info">
            You can contact your My Journey advisor on&nbsp;
            <Media query={{ maxWidth: 480 }}>
              {(screenIsSmall) =>
                screenIsSmall ? (
                  <span className="highlight-text contact-number">
                    <a href={`tel:${Strings.PROCEDURE_VERIFICATION_PHONE_NUMBER}`}>{Strings.PROCEDURE_VERIFICATION_PHONE_NUMBER} </a>
                  </span>
                ) : (
                  <span className="highlight-text contact-number">
                    {Strings.PROCEDURE_VERIFICATION_PHONE_NUMBER}
                  </span>
                )
              }
            </Media>
          </p>
        </div>
      </div>
    </div>
  );
};

SuccessView.propTypes = {
  isLoading: PropTypes.bool,
  firstName: PropTypes.string,
  email: PropTypes.string,
  signOut: PropTypes.func,
};

export default SuccessView;
